import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ShopService} from './shops.service';
import {ByHttpService} from './http.services';
import {ReplaySubject} from 'rxjs/ReplaySubject';


export class CrossChannelSummary {
  preorders: number;
  clickAndCollect: number;
  digitalOffline: number;
  shipFromStore: number;
}

@Injectable()
export class CorssChannelService {

  private crossChannelSummaryObservable: ReplaySubject<CrossChannelSummary> = new ReplaySubject(1);
  private lastCrossChannelSummary: string;

  constructor(private _shopService: ShopService, private _httpService: ByHttpService) {
  }

  public getCrossChannelSummary(organizationId: string, from: Date, to: Date):
    Observable<CrossChannelSummary> {
    const request = {
      'from': from,
      'to': to,
    };
    const nextCrossChannelSummary = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
    if (nextCrossChannelSummary !== this.lastCrossChannelSummary) {
      this.lastCrossChannelSummary = nextCrossChannelSummary;
      this._httpService.doApiPostConnection<CrossChannelSummary>('/api/analytics/crosschannel/' +
          organizationId + '/summary', request, true).subscribe((next) => {
        this.crossChannelSummaryObservable.next(next)
      })
    }

    return this.crossChannelSummaryObservable
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

}
