import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import 'style-loader!./graphShipFromStore.scss';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {NgxChartConf, ChartLineParameters, ChartParameters} from '../../../services/ngxChartConf';
import {SFSAnalyticsDataCrossChannel, SFSGraphData} from '../../../services/sFSData.service';

@Component({
  selector: 'ngx-graph-ship-from-store',
  templateUrl: './graphShipFromStore.html',
})
export class GraphShipFromStoreComponent implements OnChanges {


  private shipFromStoreChart: AmChart;
  shopId: string;
  @Input('sFSData') sFSData: SFSGraphData;

  private chartConf: NgxChartConf = new NgxChartConf();

  constructor(private AmCharts: AmChartsService) {

    const _labelFunction = (value, valueString, axis) => {
      if (value === 0) {
        return valueString;
      } else {
        return '' + valueString;
      }
    };
    const _balloonFunction = (graphDataItem, graph) => {
      return graphDataItem.values.value.toString();
    };
    const _lines = [];
    _lines.push(new ChartLineParameters('m', 'received', '#a37eff', 'received', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'pending', '#DFE166', 'pending', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'confirmed', '#8F8F8F', 'confirmed', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'rejected', '#F28773', 'rejected', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'sent', '#A3F6F4', 'sent', 'round', _balloonFunction));
    this.chartConf.setConfParameters(new ChartParameters(_lines, _labelFunction));

  }


  public setShipFromStoreChart(_shipFromStoreChart: any) {
    this.shipFromStoreChart = _shipFromStoreChart;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sFSData && changes.sFSData.currentValue) {
      this.refreshChart();
    }
  }
  private refreshChart() {
    if (this.sFSData && this.shipFromStoreChart) {
      this.AmCharts.updateChart(this.shipFromStoreChart, () => {
        this.shipFromStoreChart.dataProvider = this.sFSData.dateShipFromStore;
      });
    }
  }



}
