import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {User} from '../pages/login';

@Injectable()
export class AdminGuard implements CanActivate {

  private user: User;

  constructor(private _oauthService: OAuthService, private _router: Router) {
    this.user = _oauthService.getIdentityClaims() as User;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.checkUser(url);
  }

  checkUser(url: string): boolean {
    console.log('Check user is admin', url);
    if (this.user && this.user.roles && this.user.roles.indexOf('ROLE_ADMIN') >= 0) {
      return true;
    } else if (this.user && this.user.roles && this.user.roles.indexOf('ROLE_ADMIN_ORGANIZATION') >= 0) {
      if (url.indexOf('editShop')) {
        return true;
      } else if (url.indexOf('addShop') >= 0 && this.user.organization && this.user.organization.allowNewShop) {
        return true;
      } else if (url.indexOf('addUser') >= 0 && this.user.organization && this.user.organization.allowNewAssistant) {
        return true;
      }
    }
    return false;
  }
}
