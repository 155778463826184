import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {

  transform(value: string, _limit: string, _trail: string): string {
    if (value) {
      const limit = _limit ? parseInt(_limit, 10) : 10;
      const trail = _trail ? _trail : '...';

      return value.length > limit ? value.substring(0, limit) + trail : value;
    } else {
      return value;
    }
  }

}
