import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {map} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {Organization} from './organization.service';

export class SFSAlgorithmData {
  shops: Array<SFSAlgorithmShopData> = [];
}

export class SFSAlgorithmAnalyticsResultDto {
  id: string;
  organization: Organization;
  allowOnlineOrdersShop: Array<Shop>;
  priorityShop: DistributionUnitDto;
  internalRouteShop: Array<Shop>;
  accessibleByTransportCoShop: Array<Shop>;
}

export class ErrorDto {
  code: string;
  detail: string;
}

enum DistributionUnitType {
  ECOMMERCE,
  SHOP,
}

export class DistributionUnitDto {
  storeType: DistributionUnitType;
  shop: Shop;
}

export class DistributionUnit {
  storeType: DistributionUnitType;
  shopId: String;

  constructor(_shopId: string | null = null) {
    if (_shopId === null) {
      this.shopId = null;
      this.storeType = DistributionUnitType.ECOMMERCE;
    } else {
      this.storeType = DistributionUnitType.SHOP;
      this.shopId = _shopId;
    }
  }
}


export class SFSAnalyticsResultListDto {
  stockageDistributionAnalytics: Array<SFSAnalyticsResultDto> = [];
  totalOrders: number;
  totalStockageDistributions: number;
}

export enum UnavailableState {
  REFUND_ACCEPTED,
  REFUND_REJECTED,
  EDITING,
  UNREVIEWED,
  REVIEWED,
}

export class StockageDistributionOrderItemDto {
  orderItemId: string;
  reference: string;
  quantity: number;
  quantityRejected: number;
  state: string;
  variant: string;
  barcode: string;

  constructor(orderItemId: string, reference: string, quantity: number, quantityRejected: number,
              state: string, variant: string, barcode: string) {
    this.orderItemId = orderItemId;
    this.reference = reference;
    this.quantity = quantity;
    this.quantityRejected = quantityRejected;
    this.state = state;
    this.variant = variant;
    this.barcode = barcode;
  }
}

export class UnavailableProposalSFSAnalyticsData {
  id: string;
  products: Array<StockageDistributionOrderItemDto> = [];
  shopsInformation: Array<RejectedByInShop> = [];

  constructor(_id: string, _products: Array<StockageDistributionOrderItemDto>,
              _shopsInformation: Array<RejectedByInShop>) {
    this.id = _id;
    this.shopsInformation = _shopsInformation;
    
    const products = [];

    _products.forEach(_product => {
      let i;
      for (i = 0; i < _product.quantity; i++) {
        products.push(new StockageDistributionOrderItemDto(_product.orderItemId, _product.reference, 1,
          null, _product.state, _product.variant, _product.barcode));
      }
    });
    this.products = products;
  }
}

export class UnavailableStockageDistributionDataDto {
  id: string;
  state: string;
  orderDate: Date;
  trackingId: string;
  unavailableProposalsData: Array<UnavailableProposalSFSAnalyticsData> = [];
}

export class UnavailableStockageDistributionData {
  id: string;
  state: UnavailableState;
  expanded: boolean;
  orderDate: Date;
  trackingId: string;
  unavailableProposalsData: Array<UnavailableProposalSFSAnalyticsData> = [];

  constructor(dto: UnavailableStockageDistributionDataDto) {
    this.id = dto.id;
    switch (dto.state) {
      case 'EDITING' :
        this.state = UnavailableState.EDITING;
        break;
      case 'UNREVIEWED' :
        this.state = UnavailableState.UNREVIEWED;
        break;
    }
    this.orderDate = dto.orderDate;
    this.trackingId = dto.trackingId;
    dto.unavailableProposalsData.forEach(proposal => {
      if (proposal.products === undefined) {
        const _products = [new StockageDistributionOrderItemDto('-',
          'Información de producto no disponible', 1, 0, '', '', '')];
        this.unavailableProposalsData.push(
          new UnavailableProposalSFSAnalyticsData(proposal.id, _products, proposal.shopsInformation))
      } else {
        this.unavailableProposalsData.push(
          new UnavailableProposalSFSAnalyticsData(proposal.id, proposal.products, proposal.shopsInformation))
      }
    });

    this.expanded = false;
  }
}

export class UnavailableSFSData {
  data: Array<UnavailableStockageDistributionData> = [];
  totalNumberOfUnavailableStockageDistributions: number;

  constructor(data: Array<UnavailableStockageDistributionData>, totalNumberOfUnavailableStockageDistributions: number) {
    this.data = data;
    this.totalNumberOfUnavailableStockageDistributions = totalNumberOfUnavailableStockageDistributions;
  }
}

export class UnavailableStockageDistributionListDto {
  data: Array<UnavailableStockageDistributionDataDto> = [];
  totalNumberOfUnavailableStockageDistributions: number;
}

export class RejectedByInShop {
  rejectedBy: string;
  shopName: string;
  shopCode: string;
  avgCheckTime: number;

  constructor(_shopName: string, _shopCode: string, _rejectedBy: string, _avgCheckTime: number) {
    this.rejectedBy = _rejectedBy;
    this.shopName = _shopName;
    this.shopCode = _shopCode;
    this.avgCheckTime = _avgCheckTime;
  }
}

export class ReviewedAndAcceptedOrderItem {
  distributionUnit: DistributionUnit;
  quantityAccepted: number;

  constructor(_distributionUnit: DistributionUnit, _quantityAccepted: number) {
    this.distributionUnit = _distributionUnit;
    this.quantityAccepted = _quantityAccepted;
  }
}

export class OrderItemRefundReview {
  orderItemId: string;
  quantityRejected: number;
  accepted: Array<ReviewedAndAcceptedOrderItem>;

  constructor(_id: string, _quantityRejected: number, _accepted: Array<ReviewedAndAcceptedOrderItem>) {
    this.orderItemId = _id;
    this.quantityRejected = _quantityRejected;
    this.accepted = _accepted
  }

  thereIsAtLeastOnceAccepted(): boolean {
    return this.accepted.length > 0
  }
}

export class ProposalRefundReview {
  id: string;
  state: UnavailableState;
  orderItems: Array<OrderItemRefundReview>;

  constructor(_id: string, _orderItems: Array<OrderItemRefundReview>) {
    this.id = _id;
    this.orderItems = _orderItems;
    let thereIsAccepted = true;
    this.orderItems.forEach(orderItem => {
      thereIsAccepted = thereIsAccepted && orderItem.thereIsAtLeastOnceAccepted()
    });
    if (thereIsAccepted) {
      this.state = UnavailableState.REFUND_REJECTED;
    } else {
      this.state = UnavailableState.REFUND_ACCEPTED;
    }
  }
}

export class StockageDistributionRefundReviewed {
  id: string;
  unavailableProposals: Array<ProposalRefundReview>;

  constructor(_id: string, _proposals: Array<ProposalRefundReview>) {
    this.id = _id;
    this.unavailableProposals = _proposals;
  }

  checkIfThereIsOneRefundedUnit(): Array<OrderItemRefundReview> {
    const refundedProducts = [];
    this.unavailableProposals.forEach(proposal => {
      proposal.orderItems.forEach(orderItem => {
        if (orderItem.quantityRejected > 0) {
          refundedProducts.push(new OrderItemRefundReview(orderItem.orderItemId, orderItem.quantityRejected, null));
        }
      });
    });
    return refundedProducts;
  }
}

export class SFSAnalyticsResultDto {
  id: string;
  trackingId: string;
  date: Date;
  sellId: string;
  state: string;
  tries: Array<StockageDistributiontryDto>
}

export class StockageDistributiontryDto {
  id: string;
  previousStockageDistributionStoreId: string;
  tryDate: number;
  distributionUnits: Array<StockageDistributionProposalAnalyticsDto>;
}

export class StockageDistributionProposalAnalyticsDto {
}

export class StockageDistributionUnavailableAnalyticsDto extends StockageDistributionProposalAnalyticsDto {
  distributionItems: Array<StockageDistributionItemAnalyticsDto>;
  state: string;
  id: string;
}

export class StockageDistributionStoreAnalyticsDto extends StockageDistributionProposalAnalyticsDto {
  id: string;
  state: string;
  rejectedBy: string;
  avgCheckTime: number;
  avgDeliveryTime: number;
  distributionItems: Array<StockageDistributionItemAnalyticsDto>;
  distributionUnit: DistributionUnitDto
}

export class StockageDistributionItemAnalyticsDto {
  reference: string;
  barcode: string;
  variant: string;
  quantity: number;
  quantityRejected: number;
  state: string;
}

export class SFSAnalyticsData {
  orders: Array<Order>;
  totalOrders: number;
  totalStockageDistributions: number;

  constructor() {
    this.orders = [];
  }
}

export class SFSAnalyticsDataCrossChannel {
  shops: Array<ShopShipFromStoreData>;
  dateShipFromStore: Array<ShipFromStoreChartData>;
  received: number;
  confirmed: number;
  rejected: number;
  sent: number;

  constructor() {
    this.shops = [];
    this.dateShipFromStore = [];
    this.rejected = 0;
    this.sent = 0;
    this.confirmed = 0;
    this.received = 0;
  }
}

export class SFSGraphDto {
  state: string;
  date: Date;
  quantity: number;
}

export class SFSGraphData {
  dateShipFromStore: Array<ShipFromStoreChartData>;
  received: number;
  confirmed: number;
  rejected: number;
  redirected: number;
  pending: number;
  sent: number;

  constructor() {
    this.dateShipFromStore = [];
    this.rejected = 0;
    this.sent = 0;
    this.confirmed = 0;
    this.received = 0;
    this.redirected = 0;
    this.pending = 0;
  }
}

export class SFSTableData {
  shopName: string;
  shopId: string;
  shopCode: string;
  received: number;
  pending: number;
  confirmed: number;
  rejected: number;
  sent: number;
  avgDeliveryTime: number;
  avgCheckTime: number;

  constructor() {
    this.avgDeliveryTime = 0;
    this.avgCheckTime = 0;
    this.received = 0;
    this.confirmed = 0;
    this.rejected = 0;
    this.sent = 0;
  }
}

export class SFSByShopTableData {
  productInfo: ProductInformation;
  received: number;
  confirmed: number;
  pending: number;
  redirected: number;
  rejected: number;
  sent: number;
  avgDeliveryTime: number;
  avgCheckTime: number;

  constructor() {
    this.avgDeliveryTime = 0;
    this.avgCheckTime = 0;
    this.received = 0;
    this.confirmed = 0;
    this.pending = 0;
    this.redirected = 0;
    this.rejected = 0;
    this.sent = 0;
  }
}

export class ProductInformation {
  productName: string;
  variant: string;
  barcode: string;
  state: string;
  quantity: number;
}

export class SFSAnalyticsDataCrossChannelByShop {
  products: Array<ProductShipFromStoreData>;
  dateShipFromStore: Array<ShipFromStoreChartData>;
  received: number;
  confirmed: number;
  rejected: number;
  sent: number;

  constructor() {
    this.products = [];
    this.dateShipFromStore = [];
    this.rejected = 0;
    this.sent = 0;
    this.confirmed = 0;
    this.received = 0;
  }
}

export class ProductShipFromStoreData {
  reference: string;
  variant: string;
  received: number;
  confirmed: number;
  rejected: number;
  sent: number;
  avgDeliveryTime: number;
  avgCheckTime: number;

  constructor() {
    this.received = 0;
    this.confirmed = 0;
    this.rejected = 0;
    this.sent = 0;
    this.avgCheckTime = 0;
    this.avgDeliveryTime = 0;
  }
}

export class ShipFromStoreChartData {
  constructor(_date: Date, _received: number, _confirmed: number, _rejected: number, _sent: number,
              _redirected: number, _pending: number) {
    this.date = _date;
    this.received = _received;
    this.confirmed = _confirmed;
    this.rejected = _rejected;
    this.sent = _sent;
    this.redirected = _redirected;
    this.pending = _pending;
  }

  date: Date;
  received: number;
  redirected: number;
  pending: number;
  confirmed: number;
  rejected: number;
  sent: number;
}

export class ShopShipFromStoreData {
  shopName: string;
  shopId: string;
  received: number;
  confirmed: number;
  rejected: number;
  sent: number;
  avgDeliveryTime: number;
  avgCheckTime: number;

  constructor() {
    this.avgDeliveryTime = 0;
    this.avgCheckTime = 0;
    this.received = 0;
    this.confirmed = 0;
    this.rejected = 0;
    this.sent = 0;
  }
}

export class ShopCodeAndShopName {
  shopCode: string;
  shopName: string;

  constructor(_shopCode: string, _shopName: string) {
    this.shopCode = _shopCode;
    this.shopName = _shopName;
  }
}

export class Order {
  id: string;
  trackingId: string;
  date: Date;
  status: string;
  shopNames: string = '';
  shopIds: Array<string>;
  expanded: boolean;
  attempts: Array<OrderAttempt>;
  shippingsNumber: number;

  constructor(_id: string, _trackingId: string, _date: Date, _attempts: Array<OrderAttempt>, _status: String) {
    this.date = _date;
    this.expanded = false;
    this.attempts = _attempts;
    this.id = _id;
    this.trackingId = _trackingId;
    let shopCodesAndNames: Array<ShopCodeAndShopName> = [];
    this.shopIds = [];
    this.attempts.forEach(attempt => attempt.shops.forEach(shop => {
      if (shop.status !== 'Rechazado' && shop.status !== 'Parcialmente aceptado') {
        shopCodesAndNames.push(new ShopCodeAndShopName(shop.shopCode, shop.shopName));
        this.shopIds.push(shop.shopId)
      }
    }));
    shopCodesAndNames = shopCodesAndNames.filter(this.onlyUnique);
    shopCodesAndNames.forEach(shopCodeAndName => {
      if (shopCodeAndName.shopCode === null) {
        this.shopNames = this.shopNames + shopCodeAndName.shopName + ', '
      } else {
        this.shopNames = this.shopNames + shopCodeAndName.shopCode + ' - ' + shopCodeAndName.shopName + ', '
      }
    });
    this.shopNames = this.shopNames.substring(0, this.shopNames.length - 2);
    switch (_status) {
      case 'PENDING':
        this.status = 'Pendiente';
        break;
      case 'ACCEPTED':
        this.status = 'Confirmado';
        break;
      case 'REJECTED':
        this.status = 'Rechazado';
        break;
      case 'PARTIALLY_ACCEPTED':
        this.status = 'Parcialmente aceptado';
        break;
      case 'DELIVERED':
        this.status = 'Enviado';
        break;
    }
    this.shippingsNumber = _attempts.map(attempt => attempt.shippingsNumber).reduce(this.add, 0)
  }

  add(a, b) {
    return a + b;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}

export class OrderAttempt {
  shops: Array<ShopSFSAnalyticsData>;
  date: Date;
  shippingsNumber: number;

  constructor(_shops: Array<ShopSFSAnalyticsData>, _date: number, _shippingNumber: number) {
    this.date = new Date(_date);
    this.shops = _shops;
    this.shippingsNumber = _shippingNumber;
  }
}

export class ShopSFSAnalyticsData {
  shopName: string;
  shopId: string;
  shopCode: string;
  status: string;
  checkDate: number;
  rejectedBy: string;
  avgDeliveryTime: number;
  products: Array<OrderProduct>;

  constructor(_products: Array<OrderProduct>, _shopName: string, _shopId: string, _status: string,
              _checkDate: number, _avgDeliveryTime: number, _shopCode: string | null = null,
              _rejectedBy: string | null = null) {
    this.products = _products;
    this.shopName = _shopName;
    this.shopId = _shopId;
    this.shopCode = _shopCode;
    this.checkDate = _checkDate;
    this.avgDeliveryTime = _avgDeliveryTime;
    this.rejectedBy = _rejectedBy;
    switch (_status) {
      case 'PENDING':
        this.status = 'Pendiente';
        break;
      case 'ACCEPTED':
        this.status = 'Confirmado';
        break;
      case 'REJECTED':
        this.status = 'Rechazado';
        break;
      case 'PARTIALLY_ACCEPTED':
        this.status = 'Parcialmente aceptado';
        break;
      case 'DELIVERED':
        this.status = 'Enviado';
        break;
    }
  }
}

export class OrderProduct {
  status: string;
  name: string;
  shopName: string;
  variant: string;
  barcode: string;

  constructor(_status: string, _name: string, _variant: string, _barcode: string, _shopName: string) {
    switch (_status) {
      case 'PENDING':
        this.status = 'Pendiente';
        break;
      case 'ACCEPTED':
        this.status = 'Confirmado';
        break;
      case 'REJECTED':
        this.status = 'Rechazado';
        break;
      case 'PARTIALLY_ACCEPTED':
        this.status = 'Parcialmente aceptado';
        break;
      case 'DELIVERED':
        this.status = 'Enviado';
        break;
      case 'UNAVAILABLE':
        this.status = 'No disponible';
        break;
    }
    this.name = _name;
    this.variant = _variant;
    this.barcode = _barcode;
    this.shopName = _shopName;
  }
}

export class SFSAlgorithmShopData {
  constructor(_priority: boolean, _internal: boolean, _accessible: boolean, _storeType: string, _shop: Shop | null) {
    this.priority = _priority;
    this.internal = _internal;
    this.accessible = _accessible;
    this.storeType = _storeType;
    this.shop = _shop;
  }

  priority: boolean;
  internal: boolean;
  accessible: boolean;
  storeType: string;
  shop: Shop;
}

export class ChangeUnavailableStateBody {
  state: UnavailableState;
  stockageDistributionRefundReviewed: StockageDistributionRefundReviewed;

  constructor(_state: UnavailableState,
              stockageDistributionRefundReviewed: StockageDistributionRefundReviewed | null = null) {
    this.state = _state;
    this.stockageDistributionRefundReviewed = stockageDistributionRefundReviewed;
  }
}

@Injectable()
export class SFSDataService {
  private sFSAlgorithmDataObservable: ReplaySubject<SFSAlgorithmAnalyticsResultDto> = new ReplaySubject(1);
  private sFSAnalyticsDataObservable: ReplaySubject<SFSAnalyticsResultListDto> = new ReplaySubject(1);
  private sFSAnalyticsByTrackingIdDataObservable: ReplaySubject<Order> = new ReplaySubject(1);
  private unavailableSFSDataObservable: ReplaySubject<UnavailableStockageDistributionListDto> = new ReplaySubject(1);
  private sFSGraphDataObservable: ReplaySubject<SFSGraphDto[]> = new ReplaySubject(1);
  private sFSTableDataObservable: ReplaySubject<[Shop[], SFSTableData[]]> = new ReplaySubject(1);
  private sFSTableByShopDataObservable: ReplaySubject<SFSByShopTableData[]> = new ReplaySubject(1);
  private sFSEditingUnavailable: ReplaySubject<[string, ErrorDto]> = new ReplaySubject(1);
  private sFSReviewedUnavailable: ReplaySubject<[string, ErrorDto]> = new ReplaySubject(1);
  private lastSFSAlgorithmData: string;
  private lastSFSAnalyticsData: string;
  private lastSFSByTrackingIdData: string;
  private lastSFSGraphData: string;
  private lastSFSTableData: string;

  constructor(private _httpService: ByHttpService, private _shopService: ShopService) {
  }

  private proposalIsUnavailable(proposal: StockageDistributionProposalAnalyticsDto) {
    return !('distributionUnit' in proposal)
  }

  public getSFSAlgorithmData(organizationId: string): Observable<SFSAlgorithmData> {
    const nextSFSAlgorithmData = organizationId;
    if (nextSFSAlgorithmData !== this.lastSFSAlgorithmData) {
      this.lastSFSAlgorithmData = nextSFSAlgorithmData;
      this._httpService.doApiGetConnection<SFSAlgorithmAnalyticsResultDto>('/api/stockagedistribution/configuration/' +
        organizationId, true).subscribe((next) => {
        this.sFSAlgorithmDataObservable.next(next)
      })
    }
    return this.sFSAlgorithmDataObservable.pipe(map<SFSAlgorithmAnalyticsResultDto, SFSAlgorithmData>((result) => {
        const _sFSData = result;
        const sFSData = new SFSAlgorithmData();
        if (_sFSData.allowOnlineOrdersShop !== undefined) {
          if (_sFSData.allowOnlineOrdersShop.length > 0) {
            const _shopIds = _sFSData.allowOnlineOrdersShop.map(shop => shop.id);
            let _sFSShopData: SFSAlgorithmShopData;
            _shopIds.forEach(shopId => {
              const _internal = _sFSData.internalRouteShop.filter(shop => shop.id === shopId).length > 0;
              const _accessible = _sFSData.accessibleByTransportCoShop.filter(shop => shop.id === shopId).length > 0;
              const _priority = (_sFSData.priorityShop.storeType === DistributionUnitType.SHOP
                && _sFSData.priorityShop.shop.id === shopId);
              let _shop: Shop;
              _shop = _sFSData.allowOnlineOrdersShop.filter(shop => shop.id === shopId)[0];
              _sFSShopData = new SFSAlgorithmShopData(_priority, _internal, _accessible, 'SHOP', _shop);
              sFSData.shops.push(_sFSShopData);
            });
          }
        }
        if (_sFSData.priorityShop.storeType !== DistributionUnitType.SHOP) {
          sFSData.shops.push(new SFSAlgorithmShopData(true, false, false, 'ECOMMERCE', null));
          this.swapFirstElem(sFSData);
        } else {
          sFSData.shops.splice(0, 0, new SFSAlgorithmShopData(false, false, false, 'ECOMMERCE', null))
        }

        return sFSData;
      }),
    );
  }

  public getSFSAnalyticsData(organizationId: string, from: Date, to: Date, pageNum: number):
    Observable<SFSAnalyticsData> {
    const nextSFSAnalyticsData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to)
      + '-' + pageNum;
    if (nextSFSAnalyticsData !== this.lastSFSAnalyticsData) {
      const request = {
        'from': from,
        'to': to,
      };
      this.lastSFSAnalyticsData = nextSFSAnalyticsData;

      this._httpService.doApiPostConnection<SFSAnalyticsResultListDto>(
        '/api/stockagedistribution/' + organizationId + '/' + pageNum, request, true).subscribe((next) => {
        this.sFSAnalyticsDataObservable.next(next)
      })
    }

    return this.sFSAnalyticsDataObservable.pipe(map<SFSAnalyticsResultListDto, SFSAnalyticsData>((result) => {
        const _sFSData = result;
        const sFSData = new SFSAnalyticsData;
        sFSData.orders = [];
        sFSData.totalOrders = _sFSData.totalOrders;
        sFSData.totalStockageDistributions = _sFSData.totalStockageDistributions;
        if (_sFSData.stockageDistributionAnalytics && _sFSData.stockageDistributionAnalytics.length > 0) {
          _sFSData.stockageDistributionAnalytics.forEach(order => {
            sFSData.orders.push(this.getSFSOrderDataFromDto(order));
          });
        }
        return sFSData;
      }),
    );
  }

  private getSFSAnalyticsProductsFromDistributionItemsDto(product: StockageDistributionItemAnalyticsDto,
                                                          _proposal: StockageDistributionProposalAnalyticsDto):
    Array<OrderProduct> {
    const prods = Array<OrderProduct>();
    if (!this.proposalIsUnavailable(_proposal)) {
      const proposal = _proposal as StockageDistributionStoreAnalyticsDto;
      if (product.quantityRejected === undefined) {
        for (let i = 0; i < product.quantity; i++) {
          if (proposal.distributionUnit.storeType === DistributionUnitType.SHOP) {
            if (proposal.state === 'PARTIALLY_ACCEPTED') {
              prods.push(new OrderProduct('PARTIALLY_ACCEPTED', product.reference,
                product.variant, product.barcode, proposal.distributionUnit.shop.name));
            } else {
              prods.push(new OrderProduct(product.state, product.reference,
                product.variant, product.barcode, proposal.distributionUnit.shop.name));
            }
          } else {
            if (proposal.state === 'PARTIALLY_ACCEPTED') {
              prods.push(
                new OrderProduct('PARTIALLY_ACCEPTED', product.reference, product.variant, product.barcode,
                  'eCommerce'));
            } else {
              prods.push(
                new OrderProduct(product.state, product.reference, product.variant, product.barcode, 'eCommerce'));
            }
          }
        }
      } else {
        for (let i = 0; i < product.quantityRejected; i++) {
          if (proposal.distributionUnit.storeType === DistributionUnitType.SHOP) {
            prods.push(new OrderProduct('REJECTED', product.reference,
              product.variant, product.barcode, proposal.distributionUnit.shop.name));
          } else {
            prods.push(
              new OrderProduct('REJECTED', product.reference, product.variant, product.barcode, 'eCommerce'));
          }
        }
        for (let i = 0; i < product.quantity; i++) {
          if (proposal.distributionUnit.storeType === DistributionUnitType.SHOP) {
            prods.push(new OrderProduct('ACCEPTED', product.reference,
              product.variant, product.barcode, proposal.distributionUnit.shop.name));
          } else {
            prods.push(
              new OrderProduct('ACCEPTED', product.reference, product.variant, product.barcode, 'eCommerce'));
          }
        }
      }
    } else {
      for (let i = 0; i < product.quantity; i++) {
        prods.push(new OrderProduct('UNAVAILABLE', product.reference,
          product.variant, product.barcode, 'No disponible'));
      }
    }
    return prods;
  }

  private getSFSAnalyticsDistributionUnitFromProposal(_proposal: StockageDistributionProposalAnalyticsDto,
                                                      tryDate: number):
    ShopSFSAnalyticsData {
    let prods = Array<OrderProduct>();
    if (!this.proposalIsUnavailable(_proposal)) {
      const proposal = _proposal as StockageDistributionStoreAnalyticsDto;
      if (proposal.distributionItems && proposal.distributionItems.length > 0) {
        proposal.distributionItems.forEach(product => {
          prods = prods.concat(this.getSFSAnalyticsProductsFromDistributionItemsDto(product, proposal));
        });
      }
      if (proposal.distributionUnit.shop != null) {
        return new ShopSFSAnalyticsData(prods, proposal.distributionUnit.shop.name,
          proposal.distributionUnit.shop.id, proposal.state, tryDate + proposal.avgCheckTime,
          proposal.avgDeliveryTime / 1000, proposal.distributionUnit.shop.posId, proposal.rejectedBy);
      } else {
        return new ShopSFSAnalyticsData(prods, 'eCommerce',
          '', proposal.state, tryDate + proposal.avgCheckTime, proposal.avgDeliveryTime / 1000);
      }
    } else {
      const proposal = _proposal as StockageDistributionUnavailableAnalyticsDto;
      if (proposal.distributionItems && proposal.distributionItems.length > 0) {
        proposal.distributionItems.forEach(product => {
          prods = prods.concat(this.getSFSAnalyticsProductsFromDistributionItemsDto(product, proposal));
        });
        return new ShopSFSAnalyticsData(prods, 'No disponible',
          '', proposal.state, 0, 0);
      }
    }
  }

  private getSFSOrderDataFromDto(order: SFSAnalyticsResultDto): Order {
    const _tries = [];
    order.tries.forEach(attempt => {
      const shops = Array<ShopSFSAnalyticsData>();
      let shippingsNumber = 0;
      if (attempt.distributionUnits && attempt.distributionUnits.length > 0) {
        attempt.distributionUnits.forEach(_proposal => {
          shops.push(this.getSFSAnalyticsDistributionUnitFromProposal(_proposal, attempt.tryDate));
          if (!this.proposalIsUnavailable(_proposal)) {
            const proposal = _proposal as StockageDistributionStoreAnalyticsDto;
            if (proposal.distributionItems !== undefined && proposal.distributionItems.length > 0
              && proposal.distributionItems.filter(item => item.state === 'DELIVERED').length > 0) {
              shippingsNumber++;
            }
          }
        });
      }
      _tries.push(new OrderAttempt(shops, attempt.tryDate, shippingsNumber))
    });
    _tries.sort(function (a, b) {
      return b.date.getTime() - a.date.getTime();
    });
    return new Order(order.id, order.trackingId, new Date(order.date), _tries, order.state);
  }

  public getSFSByTrackingId(organizationId: string, from: Date, to: Date, trackingId: string)
     {
    const nextSFSByTrackingIdData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to)
      + '-' + trackingId;
    if (nextSFSByTrackingIdData !== this.lastSFSByTrackingIdData) {
      const request = {
        'from': from,
        'to': to,
        'organizationId': organizationId,
      };
      this.lastSFSByTrackingIdData = nextSFSByTrackingIdData;

      this._httpService.doApiPostConnection<SFSAnalyticsResultDto>(
        '/api/stockagedistribution/trackingId/' + trackingId, request, true).subscribe((next) => {
        this.sFSAnalyticsByTrackingIdDataObservable.next(this.getSFSOrderDataFromDto(next))
      }, (err) => { 
        this.sFSAnalyticsByTrackingIdDataObservable.next(null);
       }
      )
    }
  }

  public getSFSAnalyticsByTrackingIdDataObservable(): Observable<Order>
  {
    return this.sFSAnalyticsByTrackingIdDataObservable
  }

  public getUnavailableSFSData(organizationId: string, pageNum: number):
    Observable<UnavailableSFSData> {
    this._httpService.doApiGetConnection<UnavailableStockageDistributionListDto>(
      '/api/stockagedistribution/unavailables/' + organizationId + '/' + pageNum, true).subscribe((next) => {
      this.unavailableSFSDataObservable.next(next)
    });
    return this.unavailableSFSDataObservable.pipe(
      map<UnavailableStockageDistributionListDto, UnavailableSFSData>((result) => {
        return new UnavailableSFSData(result.data.map(dto => new UnavailableStockageDistributionData(dto)),
          result.totalNumberOfUnavailableStockageDistributions)
      }),
    );
  }

  public getSFSGraphData(organizationId: string, from: Date, to: Date, shopId: string | null = null):
    Observable<any> {
    if (!shopId) {
      const request = {
        'from': from,
        'to': to,
      };
      const nextSFSGraphData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
      if (nextSFSGraphData !== this.lastSFSGraphData) {
        this.lastSFSGraphData = nextSFSGraphData;
        this._httpService.doApiPostConnection<SFSGraphDto[]>(
          '/api/stockagedistribution/graph/' + organizationId, request, true).subscribe((next) => {
          this.sFSGraphDataObservable.next(next)
        })
      }
      return this.sFSGraphDataObservable.pipe(
        map<SFSGraphDto[], SFSGraphData>((result) => {
          const _sFSGraphData = result;
          const sFSData = new SFSGraphData();
          let date = from;
          while (date <= to) {
            let confirmed = 0;
            let sent = 0;
            let rejected = 0;
            let pending = 0;
            _sFSGraphData.forEach(order => {
              if (this.formatDate(new Date(order.date)) === this.formatDate(date)) {
                switch (order.state) {
                  case 'PENDING':
                    pending++;
                    break;
                  case 'ACCEPTED':
                    confirmed++;
                    break;
                  case 'REJECTED':
                    rejected++;
                    break;
                  case 'DELIVERED':
                    sent++;
                    break;
                }
              }
            });
            sFSData.dateShipFromStore.push(new ShipFromStoreChartData(date, confirmed + rejected + sent + pending,
              confirmed, rejected, sent, 0, pending));
            sFSData.sent = sFSData.sent + sent;
            sFSData.pending = sFSData.pending + pending;
            sFSData.confirmed = sFSData.confirmed + confirmed;
            sFSData.rejected = sFSData.rejected + rejected;
            sFSData.received = sFSData.received + sent + confirmed + rejected + pending;
            date = this.nextDate(date);
          }
          return sFSData;
        }),
      );
    } else {
      const request = {
        'from': from,
        'to': to,
      };
      const nextSFSGraphData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to) + '-' + shopId;
      if (nextSFSGraphData !== this.lastSFSGraphData) {
        this.lastSFSGraphData = nextSFSGraphData;
        this._httpService.doApiPostConnection<SFSGraphDto[]>(
          '/api/stockagedistribution/graph/shop/' + shopId, request, true).subscribe((next) => {
          this.sFSGraphDataObservable.next(next)
        })
      }
      return this.sFSGraphDataObservable.pipe(
        map<SFSGraphDto[], SFSGraphData>((result) => {
          const _sFSGraphData = result;
          const sFSData = new SFSGraphData();
          let date = from;
          while (date <= to) {
            let confirmed = 0;
            let sent = 0;
            let redirected = 0;
            let rejected = 0;
            let pending = 0;
            _sFSGraphData.forEach(order => {
              if (this.formatDate(new Date(order.date)) === this.formatDate(date)) {
                switch (order.state) {
                  case 'PENDING':
                    if (order.quantity !== undefined) {
                      pending = pending + order.quantity;
                    } else {
                      pending++;
                    }
                    break;
                  case 'ACCEPTED':
                    if (order.quantity !== undefined) {
                      confirmed = confirmed + order.quantity;
                    } else {
                      confirmed++;
                    }
                    break;
                  case 'REJECTED':
                    if (order.quantity !== undefined) {
                      rejected = rejected + order.quantity;
                    } else {
                      rejected++;
                    }
                    break;
                  case 'REDIRECTED':
                    if (order.quantity !== undefined) {
                      redirected = redirected + order.quantity;

                    } else {
                      redirected++;
                    }
                    break;
                  case 'DELIVERED':
                    if (order.quantity !== undefined) {
                      sent = sent + order.quantity;
                    } else {
                      sent++;
                    }
                    break;
                }
              }
            });
            sFSData.dateShipFromStore.push(new ShipFromStoreChartData(date,
              confirmed + rejected + sent + pending + redirected, confirmed, rejected, sent, redirected, pending));
            sFSData.sent = sFSData.sent + sent;
            sFSData.confirmed = sFSData.confirmed + confirmed;
            sFSData.rejected = sFSData.rejected + rejected;
            sFSData.redirected = sFSData.redirected + redirected;
            sFSData.pending = sFSData.pending + pending;
            sFSData.received = sFSData.received + sent + confirmed + rejected + pending + redirected;
            date = this.nextDate(date);
          }
          return sFSData;
        }),
      );
    }
  }

  public getSFSTableData(organizationId: string, from: Date, to: Date, shopId: string | null = null):
    Observable<any> {
    if (!shopId) {
      const request = {
        'from': from,
        'to': to,
      };
      const nextSFSTableData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
      if (nextSFSTableData !== this.lastSFSTableData) {
        this.lastSFSTableData = nextSFSTableData;
        zip(this._shopService.getShops(organizationId, true),
          this._httpService.doApiPostConnection<[SFSTableData]>(
            '/api/stockagedistribution/table/' + organizationId, request, true),
        ).subscribe((next) => {
          this.sFSTableDataObservable.next(next)
        })
      }
      return this.sFSTableDataObservable.pipe(
        map<[Shop[], SFSTableData[]], SFSTableData[]>((result) => {
          const _sFSTableData = result[1];
          const sFSTableData: SFSTableData[] = new Array<SFSTableData>();
          _sFSTableData.forEach(shopData => {
            const sFSTableShop: SFSTableData = new SFSTableData();
            sFSTableShop.shopName = result[0].filter(shop => shop.id === shopData.shopId)[0].name;
            sFSTableShop.shopCode = result[0].filter(shop => shop.id === shopData.shopId)[0].posId;
            sFSTableShop.avgCheckTime = shopData.avgCheckTime / 1000;
            sFSTableShop.avgDeliveryTime = shopData.avgDeliveryTime / 1000;
            sFSTableShop.shopId = shopData.shopId;
            sFSTableShop.pending = shopData.pending;
            sFSTableShop.confirmed = shopData.confirmed;
            sFSTableShop.received = shopData.received;
            sFSTableShop.rejected = shopData.rejected;
            sFSTableShop.sent = shopData.sent;
            sFSTableData.push(sFSTableShop);
          });
          return sFSTableData;
        }),
      );
    } else {
      const request = {
        'from': from,
        'to': to,
      };
      const nextSFSTableData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to) + '-' + shopId;
      if (nextSFSTableData !== this.lastSFSTableData) {
        this.lastSFSTableData = nextSFSTableData;
        this._httpService.doApiPostConnection<[SFSByShopTableData]>(
          '/api/stockagedistribution/table/shop/' + shopId, request, true).subscribe((next) => {
          this.sFSTableByShopDataObservable.next(next)
        })
      }
      return this.sFSTableByShopDataObservable.pipe(
        map<SFSByShopTableData[], SFSByShopTableData[]>((result) => {
          const _sFSByShopTableData = result;
          const sFSByShopTableData: SFSByShopTableData[] = new Array<SFSByShopTableData>();
          _sFSByShopTableData.forEach(_productData => {
            const productData = new SFSByShopTableData();
            productData.received = _productData.received;
            productData.rejected = _productData.rejected;
            productData.redirected = _productData.redirected;
            productData.pending = _productData.pending;
            productData.productInfo = _productData.productInfo;
            productData.confirmed = _productData.confirmed;
            productData.sent = _productData.sent;
            productData.avgCheckTime = _productData.avgCheckTime / 1000;
            productData.avgDeliveryTime = _productData.avgDeliveryTime / 1000;
            sFSByShopTableData.push(productData);
          });
          return sFSByShopTableData;
        }),
      );
    }
  }

  public getSFSEditingObservable(): Observable<[string, ErrorDto]> {
    return this.sFSEditingUnavailable;
  }

  public getSFSReviewedObservable(): Observable<[string, ErrorDto]> {
    return this.sFSEditingUnavailable;
  }

  public setEditingUnavailableSFS(id: string) {
    const body = new ChangeUnavailableStateBody(UnavailableState.EDITING);
    this._httpService.doApiPutConnection<boolean>(
      '/api/stockagedistribution/unavailable/changestate/' + id, body, true).subscribe((next) => {
      this.sFSEditingUnavailable.next([id, undefined])
    }, (err) => {
      this.sFSEditingUnavailable.next([id, err.error])
    })
  }

  public setReviewedUnavailableSFS(id: string, refundReviewed: StockageDistributionRefundReviewed):
    Observable<[string, ErrorDto]> {
    const body = new ChangeUnavailableStateBody(UnavailableState.REVIEWED, refundReviewed);
    this._httpService.doApiPutConnection<boolean>(
      '/api/stockagedistribution/unavailable/changestate/' + id, body, true).subscribe((next) => {
      this.sFSReviewedUnavailable.next([id, undefined])
    }, (err) => {
      this.sFSReviewedUnavailable.next([id, err.error])
    });
    return this.sFSReviewedUnavailable
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

  swapFirstElem(_sFSData: SFSAlgorithmData): SFSAlgorithmData {
    _sFSData.shops.splice(0, 0, _sFSData.shops.filter(shop => shop.priority)[0]);
    _sFSData.shops = _sFSData.shops.filter(function (item, index, inputArray) {
      return inputArray.indexOf(item) === index;
    });
    return _sFSData;
  }

  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }

  private flatMap(arr: Array<any>): Array<any> {
    return arr.reduce((acc, val) => acc.concat(val), []);
  }
}
