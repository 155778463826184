import {Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {ByHttpService} from './http.services';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {map} from 'rxjs/operators';


export class StockData {
  online: number = 0;
  myShop: number = 0;
  otherShops: number = 0;
  breakages: number = 0;
  total: number = 0;
  dateStock: Array<StockChartData> = [];
  shops: Array<ShopStockData> = [];
}

export class StockProductsData {
  online: number = 0;
  myShop: number = 0;
  otherShops: number = 0;
  breakages: number = 0;
  total: number = 0;
  dateStock: Array<StockChartData> = [];
  products: Array<ProductStockData> = [];

}

export class StockChartData {
  constructor(_date: Date, _online: number, _myShop: number, _otherShops: number) {
    this.date = _date;
    this.online = _online;
    this.otherShops = _otherShops;
    this.myShop = _myShop;
  }

  date: Date;
  online: number;
  otherShops: number;
  myShop: number;

}

export class ShopStockData {

  constructor(_shopName: string, _shopId: string, _shopCode: string,
              _online: number, _myShop: number, _otherShops: number, _breakages: number, _total: number) {
    this.shopName = _shopName;
    this.shopId = _shopId;
    this.shopCode = _shopCode;
    this.otherShops = _otherShops;
    this.total = _total;
    this.myShop = _myShop;
    this.online = _online;
    this.breakages = _breakages;
  }

  shopId: string;
  shopName: string;
  shopCode: string;
  online: number;
  myShop: number;
  otherShops: number;
  breakages: number;
  total: number;
}

export class ProductStockData {

  constructor(_productName: string, _variant: string, _barcode: string,
              _online: number, _myShop: number, _otherShops: number, _breakages: number, _total: number) {
    this.productName = _productName;
    this.variant = _variant;
    this.barcode = _barcode;
    this.otherShops = _otherShops;
    this.total = _total;
    this.myShop = _myShop;
    this.online = _online;
    this.breakages = _breakages;
  }

  productName: string;
  variant: string;
  barcode: string;
  online: number;
  myShop: number;
  otherShops: number;
  breakages: number;
  total: number;
}

export class AnalyticsStockageDto {
  shopId: string;
  skuId: string;
  barcode: string;
  date: string;
  total: number;
  myShop: number;
  online: number;
  otherShops: number;
  stockOk: number;
  brokenStock: number;
  productName: string;
  variantName: string
}

@Injectable()
export class StockService {

  private stockObservable: ReplaySubject<[Shop[], AnalyticsStockageDto[]]> = new ReplaySubject(1);
  private stockByShopObservable: ReplaySubject<[Shop[], AnalyticsStockageDto[]]> = new ReplaySubject(1);
  private stockProductObservable: ReplaySubject<AnalyticsStockageDto[]> = new ReplaySubject(1);
  private lastStockData: string;

  constructor(private _shopService: ShopService, private _httpService: ByHttpService) {
  }

  public getStockData(organizationId: string, from: Date, to: Date,
                      shopsOrProducts: string, shopId: string | null = null):
    Observable<any> {
    const request = {
      'from': from,
      'to': to,
    };
    if (shopsOrProducts === 'shops' && shopId === null) {
      const nextStockData = organizationId + '-' + shopsOrProducts + '-' +
        this.formatDate(from) + '-' + this.formatDate(to);
      if (nextStockData !== this.lastStockData) {
        this.lastStockData = nextStockData;
        zip(this._shopService.getShops(organizationId, true),
          this._httpService.doApiPostConnection<[AnalyticsStockageDto]>('/api/analytics/stockage/'
            + shopsOrProducts, request, true),
        ).subscribe((next) => {
          this.stockObservable.next(next)
        })
      }

      return this.stockObservable.pipe(
        map<[Shop[], AnalyticsStockageDto[]], StockData>((result) => {
          let shops = result[0];
          const _stockData = result[1];
          shops = shops.filter(shop =>
            _stockData.map(stockData => stockData.shopId).indexOf(shop.id) !== -1).filter(this.onlyUnique);
          const stockData = new StockData();

          stockData.online = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
            .map(analyticsStockageDto => analyticsStockageDto.online)
            .reduce((a, b) => a + b, 0);
          stockData.myShop = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
            .map(analyticsStockageDto => analyticsStockageDto.myShop)
            .reduce((a, b) => a + b, 0);
          stockData.otherShops = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
            .map(analyticsStockageDto => analyticsStockageDto.otherShops)
            .reduce((a, b) => a + b, 0);
          stockData.breakages = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
            .map(analyticsStockageDto => analyticsStockageDto.brokenStock)
            .reduce((a, b) => a + b, 0);
          stockData.total = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
            .map(analyticsStockageDto => analyticsStockageDto.total)
            .reduce((a, b) => a + b, 0);

          let date = from;
          while (date <= to) {
            const online = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .filter(analyticsStockageDto => shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
              .map(analyticsStockageDto => analyticsStockageDto.online)
              .reduce((a, b) => a + b, 0);
            const myShop = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .filter(analyticsStockageDto => shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
              .map(analyticsStockageDto => analyticsStockageDto.myShop)
              .reduce((a, b) => a + b, 0);
            const otherShops = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .filter(analyticsStockageDto => shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => shops.map(shop => shop.id).indexOf(analyticsStockageDto.shopId) !== -1)
              .map(analyticsStockageDto => analyticsStockageDto.otherShops)
              .reduce((a, b) => a + b, 0);

            stockData.dateStock.push(new StockChartData(date, online, myShop, otherShops));
            date = this.nextDate(date);
          }

          stockData.shops = [];
          shops.filter(shop => _stockData.map(data => data.shopId).indexOf(shop.id) !== -1).forEach(shop => {
            const online = _stockData.filter(analyticsStockageDto => analyticsStockageDto.shopId === shop.id)
              .map(analyticsStockageDto => analyticsStockageDto.online)
              .reduce((a, b) => a + b, 0);
            const myShop = _stockData.filter(analyticsStockageDto => analyticsStockageDto.shopId === shop.id)
              .map(analyticsStockageDto => analyticsStockageDto.myShop)
              .reduce((a, b) => a + b, 0);
            const otherShops = _stockData.filter(analyticsStockageDto => analyticsStockageDto.shopId === shop.id)
              .map(analyticsStockageDto => analyticsStockageDto.otherShops)
              .reduce((a, b) => a + b, 0);
            const breakages = _stockData.filter(analyticsStockageDto => analyticsStockageDto.shopId === shop.id)
              .map(analyticsStockageDto => analyticsStockageDto.brokenStock)
              .reduce((a, b) => a + b, 0);
            const total = _stockData.filter(analyticsStockageDto => analyticsStockageDto.shopId === shop.id)
              .map(analyticsStockageDto => analyticsStockageDto.total)
              .reduce((a, b) => a + b, 0);
            stockData.shops.push(new ShopStockData(shop.name, shop.id, shop.posId, online, myShop, otherShops,
              breakages, total));
          });
          return stockData;
        }),
      );
    } else if (shopsOrProducts === 'shops' && shopId !== null) {
      const nextStockData = organizationId + '-' + shopsOrProducts + '-' + shopId + '-' +
        this.formatDate(from) + '-' + this.formatDate(to);
      if (nextStockData !== this.lastStockData) {
        this.lastStockData = nextStockData;
        zip(this._shopService.getShops(organizationId, true),
          this._httpService.doApiPostConnection<[AnalyticsStockageDto]>('/api/analytics/stockage/'
            + shopsOrProducts + '/' + shopId, request, true),
        ).subscribe((next) => {
          this.stockByShopObservable.next(next)
        })
      }

      return this.stockByShopObservable.pipe(
        map<[Shop[], AnalyticsStockageDto[]], StockProductsData>((result) => {
          const shops = result[0];
          const _stockData = result[1];

          const stockData = new StockProductsData();

          stockData.online = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .map(analyticsStockageDto => analyticsStockageDto.online)
            .reduce((a, b) => a + b, 0);
          stockData.myShop = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .map(analyticsStockageDto => analyticsStockageDto.myShop)
            .reduce((a, b) => a + b, 0);
          stockData.otherShops = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .map(analyticsStockageDto => analyticsStockageDto.otherShops)
            .reduce((a, b) => a + b, 0);
          stockData.breakages = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .map(analyticsStockageDto => analyticsStockageDto.brokenStock)
            .reduce((a, b) => a + b, 0);
          stockData.total = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .map(analyticsStockageDto => analyticsStockageDto.total)
            .reduce((a, b) => a + b, 0);

          let date = from;
          while (date <= to) {
            const online = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .filter(analyticsStockageDto => shopId ? analyticsStockageDto.shopId === shopId : true)
              .map(analyticsStockageDto => analyticsStockageDto.online)
              .reduce((a, b) => a + b, 0);
            const myShop = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .filter(analyticsStockageDto => shopId ? analyticsStockageDto.shopId === shopId : true)
              .map(analyticsStockageDto => analyticsStockageDto.myShop)
              .reduce((a, b) => a + b, 0);
            const otherShops = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .filter(analyticsStockageDto => shopId ? analyticsStockageDto.shopId === shopId : true)
              .map(analyticsStockageDto => analyticsStockageDto.otherShops)
              .reduce((a, b) => a + b, 0);

            stockData.dateStock.push(new StockChartData(date, online, myShop, otherShops));
            date = this.nextDate(date);
          }

          stockData.products = [];

          const productNames = _stockData.filter(analyticsStockageDto =>
            shopId ? analyticsStockageDto.shopId === shopId : true)
            .map(analyticsStockageDto =>
              analyticsStockageDto.barcode + '-' + analyticsStockageDto.productName +
              '-' + analyticsStockageDto.variantName);
          productNames.filter(this.onlyUnique).forEach(productName => {
            const barcode = productName.split('-')[0];
            const name = productName.split('-')[1];
            const variant = productName.split('-')[2];
            const online = _stockData.filter(analyticsStockageDto =>
              shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
                analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.online)
              .reduce((a, b) => a + b, 0);
            const myShop = _stockData.filter(analyticsStockageDto =>
              shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
                analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.myShop)
              .reduce((a, b) => a + b, 0);
            const otherShops = _stockData.filter(analyticsStockageDto =>
              shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
                analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.otherShops)
              .reduce((a, b) => a + b, 0);
            const breakages = _stockData.filter(analyticsStockageDto =>
              shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
                analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.brokenStock)
              .reduce((a, b) => a + b, 0);
            const total = _stockData.filter(analyticsStockageDto =>
              shopId ? analyticsStockageDto.shopId === shopId : true)
              .filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
                analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.total)
              .reduce((a, b) => a + b, 0);
            stockData.products.push(new ProductStockData(name, variant, barcode, online, myShop, otherShops,
              breakages, total));
          });

          return stockData;
        }),
      );
    } else if (shopsOrProducts === 'products') {
      const nextStockData = organizationId + '-' + shopsOrProducts + '-' +
        this.formatDate(from) + '-' + this.formatDate(to);
      if (nextStockData !== this.lastStockData) {
        this.lastStockData = nextStockData;
        this._httpService.doApiPostConnection<[AnalyticsStockageDto]>('/api/analytics/stockage/'
          + shopsOrProducts, request, true).subscribe((next) => {
          this.stockProductObservable.next(next)
        })
      }

      return this.stockProductObservable.pipe(
        map<AnalyticsStockageDto[], StockProductsData>((result) => {
          const _stockData = result;

          const stockData = new StockProductsData();

          stockData.online = _stockData.map(analyticsStockageDto => analyticsStockageDto.online)
            .reduce((a, b) => a + b, 0);
          stockData.myShop = _stockData.map(analyticsStockageDto => analyticsStockageDto.myShop)
            .reduce((a, b) => a + b, 0);
          stockData.otherShops = _stockData.map(analyticsStockageDto => analyticsStockageDto.otherShops)
            .reduce((a, b) => a + b, 0);
          stockData.breakages = _stockData.map(analyticsStockageDto => analyticsStockageDto.brokenStock)
            .reduce((a, b) => a + b, 0);
          stockData.total = _stockData.map(analyticsStockageDto => analyticsStockageDto.total)
            .reduce((a, b) => a + b, 0);

          let date = from;
          while (date <= to) {
            const online = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .map(analyticsStockageDto => analyticsStockageDto.online)
              .reduce((a, b) => a + b, 0);
            const myShop = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .map(analyticsStockageDto => analyticsStockageDto.myShop)
              .reduce((a, b) => a + b, 0);
            const otherShops = _stockData.filter(analyticsStockageDto =>
              analyticsStockageDto.date === this.formatDate(date))
              .map(analyticsStockageDto => analyticsStockageDto.otherShops)
              .reduce((a, b) => a + b, 0);

            stockData.dateStock.push(new StockChartData(date, online, myShop, otherShops));
            date = this.nextDate(date);
          }

          stockData.products = [];

          const productNames = _stockData.map(analyticsStockageDto =>
            analyticsStockageDto.barcode + '-' + analyticsStockageDto.productName +
            '-' + analyticsStockageDto.variantName);
          productNames.filter(this.onlyUnique).forEach(productName => {
            const barcode = productName.split('-')[0];
            const name = productName.split('-')[1];
            const variant = productName.split('-')[2];
            const online = _stockData.filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
              analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.online)
              .reduce((a, b) => a + b, 0);
            const myShop = _stockData.filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
              analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.myShop)
              .reduce((a, b) => a + b, 0);
            const otherShops = _stockData.filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
              analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.otherShops)
              .reduce((a, b) => a + b, 0);
            const breakages = _stockData.filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
              analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.brokenStock)
              .reduce((a, b) => a + b, 0);
            const total = _stockData.filter(analyticsStockageDto => analyticsStockageDto.barcode + '-' +
              analyticsStockageDto.productName + '-' + analyticsStockageDto.variantName === productName)
              .map(analyticsStockageDto => analyticsStockageDto.total)
              .reduce((a, b) => a + b, 0);
            stockData.products.push(new ProductStockData(name, variant, barcode, online, myShop, otherShops,
              breakages, total));
          });
          return stockData;
        }),
      );
    }

  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }


}
