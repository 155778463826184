import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import 'style-loader!./graphUsage.scss';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {AppUsageData} from '../../../services/appUsage.service';
import {DateUtilService} from '../../../services/dateUtil.service';
import {NgxChartConf, ChartLineParameters, ChartParameters} from '../../../services/ngxChartConf';

@Component({
  selector: 'ngx-graph-usage',
  templateUrl: './graphUsage.html',
})
export class GraphUsageComponent implements OnChanges {


  usage: number = 0;

  private usageChart: AmChart;
  @Input('shopId') shopId: string;
  @Input('isDashboard') isDashboard: boolean;
  @Input('appUsageData') appUsageData: AppUsageData;

  private chartConf: NgxChartConf = new NgxChartConf();

  constructor(private AmCharts: AmChartsService, private dateUtil: DateUtilService) {
    const _labelFunction = (value, valueString, axis) => {
      if (value === 0) {
        return valueString;
      } else {
        return '' + this.dateUtil.secondsTohhmmss(value * 60);
      }
    };
    const _balloonFunction = (graphDataItem, graph) => {
      const value = graphDataItem.values.value * 60;
      return this.dateUtil.secondsTohhmmss(value);
    };
    const _lines = [];
    _lines.push(new ChartLineParameters('m', 'duration', '#a37eff', 'duration', 'round', _balloonFunction));
    this.chartConf.setConfParameters(new ChartParameters(_lines, _labelFunction));
  }


  public setUsageChart(_usageChart: any) {
    this.usageChart = _usageChart;
    if (this.appUsageData) {
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appUsageData.currentValue) {
      this.appUsageData = changes.appUsageData.currentValue;
      this.refreshChart();
    }
  }

  private refreshChart() {
    if (this.appUsageData && this.usageChart) {
      this.usage = this.appUsageData.usage;
      this.AmCharts.updateChart(this.usageChart, () => {
        this.usageChart.dataProvider = this.appUsageData.dateUsage;
      });
    }
  }
}
