import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {RouterModule} from '@angular/router';
import {AmChartsModule} from '@amcharts/amcharts3-angular';

/*
 * Platform and Environment providers/directives/pipes
 */
import {routing} from './app.routing';
// App is our top level component
import {AppComponent} from './app.component';
import {AppState} from './app.service';
import {GlobalState} from './global.state';
import {NgaModule} from './theme/nga.module';
import {PagesModule} from './pages/pages.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {LocaleService, LocalizationModule} from 'angular-l10n';
import {ApiModule} from './services/api.module';
import {AngularFireModule} from 'angularfire2';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiConfig} from './app-config';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {APP_CONFIG} from './pages/app.config';
import {AgmCoreModule} from '@agm/core';


// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBFqdYYAB9e_DZkc4r1J9b2tLgebqOI_U0',
  authDomain: 'misako-buyyourse-1526644518928.firebaseapp.com',
  databaseURL: 'https://misako-buyyourse-1526644518928.firebaseio.com/',
  storageBucket: 'misako-buyyourse-1526644518928.appspot.com',
  messagingSenderId: '904944524024',
};

export const APICONFIG: ApiConfig = {
  HOST: 'dev.app.misako.com',
  SECURE: true,
  APP_ID: 'client_dashboard',
  APP_SECRET: '96sH54qY5prgYV35eZqLcgG7',
  APP_ROLE: 'ROLE_ADMIN_ORGANIZATION+ROLE_ADMIN',
  getBaseUrl(): string {
    return 'http' + (this.SECURE ? 's' : '') + '://' + this.HOST;
  },
  getWsUrl(): string {
    return 'ws' + (this.SECURE ? 's' : '') + '://' + this.HOST;
  },
  STRIPE_CLIENT_ID: 'Fake',
};


/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
  ],
  imports: [ // import Angular's modules
    BrowserModule,
    HttpClientModule,
    HttpModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    NgbModule.forRoot(),
    PagesModule,
    routing,
    OAuthModule.forRoot(),
    LocalizationModule.forRoot(),
    ApiModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyD7tUgZydpO_zmPw6KeSesxTIT_P-dDDQM', libraries: ['places']}),
    AmChartsModule,
  ],
  providers: [ // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    {provide: APP_CONFIG, useValue: APICONFIG},
  ],
})

export class AppModule {

  constructor(public appState: AppState, public localeService: LocaleService) {
    this.localeService.addConfiguration()
      .addLanguages(['es'])
      .setCookieExpiration(30)
      .defineDefaultLocale('es', 'ES')
      .defineCurrency('EUR');

  }

}
