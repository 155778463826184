import {Component} from '@angular/core';

import {GlobalState} from '../../../global.state';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';

import 'style-loader!./baPageTop.scss';
import {User} from '../../../pages/login';
import {Organization, OrganizationService} from '../../../services/organization.service';
import {Location} from '@angular/common';

@Component({
  selector: 'ngx-ba-page-top',
  templateUrl: './baPageTop.html',
})
export class BaPageTopComponent {

  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;
  user: User = null;
  organizations: Array<Organization> = Array();
  selectedOrganization: Organization;
  title: string | null;
  color: string | null;
  fullScreen: boolean = false;

  constructor(private _state: GlobalState, private _oauthService: OAuthService, private _router: Router,
              private _organizationService: OrganizationService, private _location: Location) {
    this.user = _oauthService.getIdentityClaims() as User;
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
    this._state.subscribe('menu.activeLink', (currentItem) => {
      this.title = currentItem.title;
      if (currentItem.materialColor) {
        this.color = currentItem.materialColor;
      }
    });
    this._state.subscribe('fullScreen', (currentItem) => {
      this.fullScreen = currentItem;
    });

    if (this.user && this.user.roles && this.user.roles.indexOf('ROLE_ADMIN') >= 0) {
      this._organizationService.getOrganizations().subscribe((_organizations) => {
        if (_organizations) {
          this.organizations = _organizations;
          if (!this.selectedOrganization && this.organizations.length > 0) {
            const lastOrganizationId = localStorage.getItem('lastOrganizationId');
            if (lastOrganizationId) {
              for (let i = 0; i < _organizations.length; i++) {
                if (_organizations[i].id === lastOrganizationId) {
                  this.selectedOrganization = _organizations[i];
                }
              }
            }
            if (!this.selectedOrganization) {
              this.selectedOrganization = this.organizations[0];
            }
            this._organizationService.setOrganization(this.selectedOrganization);
          }
        }
      }, (reject) => {
        console.log('Error while recover organizations', reject);
      });
    } else if (this.user && this.user.roles && this.user.roles.indexOf('ROLE_ADMIN_ORGANIZATION') >= 0) {
      this._organizationService.getOrganizations(this.user.organization.id, true, this.user.organization)
        .subscribe((orgs) => {
          if (orgs && orgs.length > 0) {
            this.setSelectedOrganization(orgs[0]);
          }
        });
    }
  }

  public onChangeOrganization(organizationId: string) {
    console.log('Change to organization', organizationId);
    for (let i = 0; i < this.organizations.length; ++i) {
      const organization = this.organizations[i];
      if (organization.id === organizationId) {
        this.setSelectedOrganization(organization);
      }
    }
  }

  public baToggleMenu(event) {
    event.preventDefault();
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  public onCancel() {
    event.preventDefault();
    this._location.back();
    return false;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }

  public signout() {
    this._oauthService.logOut(false);
    this._router.navigate(['login']);
  }

  private setSelectedOrganization(organization: Organization) {
    console.log('Change to organization', organization);
    if (organization && organization.id) {
      this.selectedOrganization = organization;
      this._organizationService.setOrganization(organization);
    }
  }
}
