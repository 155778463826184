import {RouterModule, Routes} from '@angular/router';
import {PagesComponent} from './pages.component';
import {ModuleWithProviders} from '@angular/core';
import {AuthGuard} from '../services/auth-guard.services';
import {AdminGuard} from '../services/admin-auth.services';
// noinspection TypeScriptValidateTypes

// export function loadChildren(path) { return System.import(path); };

export const routes: Routes = [
  {
    path: 'login',
    loadChildren: 'app/pages/login/login.module#LoginModule',
  },
  {
    path: 'register',
    loadChildren: 'app/pages/register/register.module#RegisterModule',
  },
  {
    path: 'pages',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', loadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule'},
      {path: 'stock', loadChildren: 'app/pages/stock/stock.module#StockModule'},
      {path: 'crossChannel', loadChildren: 'app/pages/crossChannel/crossChannel.module#CrossChannelModule'},
      {path: 'usage', loadChildren: 'app/pages/usage/usage.module#UsageModule'},
      {path: 'management', loadChildren: 'app/pages/management/management.module#ManagementModule'},
    ],
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
