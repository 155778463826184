import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {map} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';

export class ClickCollectData {
  received: number;
  completed: number;
  partial: number;
  cancelled: number;
  fulfilled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
  avgPreparingTime: number;
  shops: Array<ClickCollectShopData> = [];
  dateClickCollect: Array<ClickCollectChartData> = [];
}

export class ClickCollectByShopData {
  received: number;
  completed: number;
  partial: number;
  cancelled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
  products: Array<ClickCollectProductData> = [];
  dateClickCollect: Array<ClickCollectChartData> = [];
}

export class ClickCollectChartData {
  constructor(_date: Date, _received: number, _completed: number, _partial: number, _cancelled: number) {
    this.date = _date;
    this.received = _received;
    this.completed = _completed;
    this.partial = _partial;
    this.cancelled = _cancelled;
  }

  date: Date;
  received: number;
  completed: number;
  partial: number;
  cancelled: number;
}

export class ClickCollectAnalyticsResultDto {
  id: ClickCollectAnalyticsResultIdentifierDto;
  total: number;
  pending: number;
  accepted: number;
  rejected: number;
  partiallyAccepted: number;
  fulfilled: number;
  avgCheckTime: number;
  avgDeliveryTime: number;
}

export class ClickCollectAnalyticsResultIdentifierDto {
  shopId: String;
  date: number;
}

class ClickCollectAnalyticsByShopResultDto {
  id: ClickCollectAnalyticsByShopResultIdentifierDto;
  total: number;
  pending: number;
  accepted: number;
  rejected: number;
  partiallyAccepted: number;
  fulfilled: number;
  avgCheckTime: number;
  avgDeliveryTime: number;
}

export class ClickCollectAnalyticsByShopResultIdentifierDto {
  reference: string;
  variant: string;
  date: number;
  barcode: string;
}

export class ClickCollectShopData {
  constructor(_received: number, _completed: number, _partial: number, _cancelled: number,
              _shopName: string, _shopId: string, _shopCode: string, _avgDeliveryTime: number, _avgCheckTime: number) {
    this.received = _received;
    this.completed = _completed;
    this.partial = _partial;
    this.cancelled = _cancelled;
    this.shopName = _shopName;
    this.shopCode = _shopCode;
    this.shopId = _shopId;
    this.avgDeliveryTime = _avgDeliveryTime;
    this.avgCheckTime = _avgCheckTime;
  }

  shopId: string;
  shopName: string;
  shopCode: string
  received: number;
  completed: number;
  partial: number;
  cancelled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
}

export class ClickCollectProductData {
  constructor(_received: number, _completed: number, _partial: number, _cancelled: number,
              _reference: string, _variant: string, _barcode: string, _avgDeliveryTime: number, _avgCheckTime: number) {
    this.received = _received;
    this.completed = _completed;
    this.partial = _partial;
    this.cancelled = _cancelled;
    this.reference = _reference;
    this.variant = _variant;
    this.barcode = _barcode;
    this.avgDeliveryTime = _avgDeliveryTime;
    this.avgCheckTime = _avgCheckTime;
  }

  reference: string;
  variant: string;
  barcode: string;
  received: number;
  completed: number;
  partial: number;
  cancelled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
}

@Injectable()
export class ClickCollectDataService {
  private clickCollectDataObservable: ReplaySubject<[Shop[], ClickCollectAnalyticsResultDto[]]> = new ReplaySubject(1);
  private lastClickCollectData: string;
  private clickCollectDataByShopObservable: ReplaySubject<ClickCollectAnalyticsByShopResultDto[]> =
    new ReplaySubject(1);

  constructor(private _shopService: ShopService, private _httpService: ByHttpService) {
  }

  public getClickCollectData(organizationId: string, from: Date, to: Date, shopId: string | null = null):
    Observable<any> {
    if (!shopId) {
      const request = {
        'from': from,
        'to': to,
      };
      const nextClickCollectData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
      if (nextClickCollectData !== this.lastClickCollectData) {
        this.lastClickCollectData = nextClickCollectData;
        zip(this._shopService.getShops(organizationId, true),
          this._httpService.doApiPostConnection<[ClickCollectAnalyticsResultDto]>('/api/analytics/clickandcollect/'
            + organizationId,
            request, true),
        ).subscribe((next) => {
          this.clickCollectDataObservable.next(next)
        })
      }

      return this.clickCollectDataObservable.pipe(
        map<[Shop[], ClickCollectAnalyticsResultDto[]], ClickCollectData>((result) => {
          const shops = result[0];
          const _clickCollectData = result[1];

          const clickCollectData = new ClickCollectData();
          clickCollectData.fulfilled = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.fulfilled)
            .reduce((a, b) => a + b, 0);
          clickCollectData.received = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.total)
            .reduce((a, b) => a + b, 0);
          clickCollectData.completed = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.accepted)
            .reduce((a, b) => a + b, 0);
          clickCollectData.partial = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.partiallyAccepted)
            .reduce((a, b) => a + b, 0);
          clickCollectData.cancelled = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.rejected)
            .reduce((a, b) => a + b, 0);
          clickCollectData.avgDeliveryTime = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true).filter(shopDateData => shopDateData.avgDeliveryTime)
            .map(shopDateData => shopDateData.avgDeliveryTime)
            .reduce((a, b) => a + b, 0) / 1000;
          clickCollectData.avgCheckTime = _clickCollectData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true).filter(shopDateData => shopDateData.avgCheckTime)
            .map(shopDateData => shopDateData.avgCheckTime)
            .reduce((a, b) => a + b, 0) / 1000;
          let date = from;
          while (date <= to) {
            const received = _clickCollectData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.total)
              .reduce((a, b) => a + b, 0);
            const completed = _clickCollectData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.accepted)
              .reduce((a, b) => a + b, 0);
            const partial = _clickCollectData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.partiallyAccepted)
              .reduce((a, b) => a + b, 0);
            const cancelled = _clickCollectData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.rejected)
              .reduce((a, b) => a + b, 0);
            clickCollectData.dateClickCollect.push(new ClickCollectChartData(date,
              received, completed, partial, cancelled));
            date = this.nextDate(date);
          }

          clickCollectData.shops = [];
          shops.filter(shop => _clickCollectData.map(data => data.id.shopId).indexOf(shop.id) !== -1).forEach(shop => {
            let avgDeliveryTime: number;
            let avgCheckTime: number;
            const received = _clickCollectData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.total)
              .reduce((a, b) => a + b, 0);
            const completed = _clickCollectData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.accepted)
              .reduce((a, b) => a + b, 0);
            const partial = _clickCollectData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.partiallyAccepted)
              .reduce((a, b) => a + b, 0);
            const cancelled = _clickCollectData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.rejected)
              .reduce((a, b) => a + b, 0);
            if (_clickCollectData.filter(shopDate =>
              shopDate.avgDeliveryTime > 0 && shopDate.id.shopId === shop.id).length > 0)  {
              avgDeliveryTime = _clickCollectData
                .filter(shopDate => shopDate.id.shopId === shop.id)
                .map(shopDate => shopDate.avgDeliveryTime ? shopDate.avgDeliveryTime : 0)
                .reduce((a, b) => a + b, 0) / (1000 *
                _clickCollectData.filter(shopDate =>
                  shopDate.avgDeliveryTime > 0 && shopDate.id.shopId === shop.id).length);
            } else {
              avgDeliveryTime = 0;
            }
            if (_clickCollectData.filter(shopDate =>
              shopDate.avgCheckTime > 0 && shopDate.id.shopId === shop.id).length > 0)  {
              avgCheckTime = _clickCollectData
                .filter(shopDate => shopDate.id.shopId === shop.id)
                .map(shopDate => shopDate.avgCheckTime ? shopDate.avgCheckTime : 0)
                .reduce((a, b) => a + b, 0) / (1000 *
                _clickCollectData.filter(shopDate =>
                  shopDate.avgCheckTime > 0 && shopDate.id.shopId === shop.id).length);
            } else {
              avgCheckTime = 0;
            }
            clickCollectData.shops.push(
              new ClickCollectShopData(received, completed, partial, cancelled, shop.name, shop.id, shop.posId,
                avgDeliveryTime, avgCheckTime));
          });
          clickCollectData.avgCheckTime = clickCollectData.avgCheckTime / clickCollectData.shops.length;
          clickCollectData.avgDeliveryTime = clickCollectData.avgDeliveryTime / clickCollectData.shops.length;
          return clickCollectData;
        }),
      );
    } else {
      const request = {
        'from': from,
        'to': to,
      };
      const nextClickCollectData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to) + shopId;
      if (nextClickCollectData !== this.lastClickCollectData) {
        this.lastClickCollectData = nextClickCollectData;
        this._httpService.doApiPostConnection<[ClickCollectAnalyticsByShopResultDto]>(
          '/api/analytics/clickandcollect/shop/' + shopId, request, true).subscribe((next) => {
          this.clickCollectDataByShopObservable.next(next)
        })
      }

      return this.clickCollectDataByShopObservable.pipe(
        map<ClickCollectAnalyticsByShopResultDto[], ClickCollectByShopData>((result) => {
          const _clickCollectByShopData = result;
          const clickCollectByShopData = new ClickCollectByShopData();
          clickCollectByShopData.received = _clickCollectByShopData.map(shopData => shopData.total)
            .reduce((a, b) => a + b, 0);
          clickCollectByShopData.completed = _clickCollectByShopData.map(shopData => shopData.accepted)
            .reduce((a, b) => a + b, 0);
          clickCollectByShopData.partial = _clickCollectByShopData.map(shopData => shopData.partiallyAccepted)
            .reduce((a, b) => a + b, 0);
          clickCollectByShopData.cancelled = _clickCollectByShopData.map(shopData => shopData.rejected)
            .reduce((a, b) => a + b, 0);
          clickCollectByShopData.avgDeliveryTime = _clickCollectByShopData
            .filter(shopDateData => shopDateData.avgDeliveryTime)
            .map(shopData => shopData.avgDeliveryTime).reduce((a, b) => a + b, 0) / 1000;
          clickCollectByShopData.avgCheckTime = _clickCollectByShopData
            .filter(shopDateData => shopDateData.avgCheckTime).map(shopData => shopData.avgCheckTime)
            .reduce((a, b) => a + b, 0) / 1000;
          let date = from;
          while (date <= to) {
            const received = _clickCollectByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.total)
              .reduce((a, b) => a + b, 0);
            const completed = _clickCollectByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.accepted)
              .reduce((a, b) => a + b, 0);
            const partial = _clickCollectByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.partiallyAccepted)
              .reduce((a, b) => a + b, 0);
            const cancelled = _clickCollectByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.rejected)
              .reduce((a, b) => a + b, 0);
            clickCollectByShopData.dateClickCollect.push(new ClickCollectChartData(date,
              received, completed, partial, cancelled));
            date = this.nextDate(date);
          }
          clickCollectByShopData.products = [];
          _clickCollectByShopData.forEach(product => {
            clickCollectByShopData.products.push(
              new ClickCollectProductData(product.total, product.accepted, product.partiallyAccepted, product.rejected,
                product.id.reference, product.id.variant, product.id.barcode,
                product.avgDeliveryTime ? product.avgDeliveryTime / 1000 : 0,
                product.avgCheckTime ? product.avgCheckTime / 1000 : 0));
          });

          const group_to_values = clickCollectByShopData.products.reduce(function (obj, item) {
            obj[item.reference + ' - ' + item.variant] = obj[item.reference + ' - ' + item.variant] || [];
            obj[item.reference + ' - ' + item.variant].push(item);
            return obj;
          }, {});

          const groups = Object.keys(group_to_values).map(function (key) {
            return {reference: key, products: group_to_values[key]};
          });
          clickCollectByShopData.products = [];
          const prod = [];
          for (let i = 0; i < groups.length; i++) {
            prod[i] = groups[i].products.reduce(function (previousValue, currentValue) {
              return {
                reference: previousValue.reference,
                variant: previousValue.variant,
                avgCheckTime: groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length > 0 ?
                  previousValue.avgCheckTime + currentValue.avgCheckTime
                / groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length : 0,
                avgDeliveryTime: groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length > 0 ?
                  previousValue.avgDeliveryTime + currentValue.avgDeliveryTime
                / groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length : 0,
                received: previousValue.received + currentValue.received,
                completed: previousValue.completed + currentValue.completed,
                partial: previousValue.partial + currentValue.partial,
                cancelled: previousValue.cancelled + currentValue.cancelled,
              }
            });
            clickCollectByShopData.products.push(new ClickCollectProductData(
              prod[i].received, prod[i].completed, prod[i].partial, prod[i].cancelled, prod[i].reference,
              prod[i].variant, prod[i].barcode, prod[i].avgDeliveryTime, prod[i].avgCheckTime));
          }
          return clickCollectByShopData;
        }),
      );
    }
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }
}
