export const PAGES_MENU = [
  {
    path: 'pages',
    children: [
      {
        path: 'dashboard',
        data: {
          menu: {
            title: 'Dashboard',
            materialIcon: 'donut_large',
            materialColor: '#9C86D2',
            backgroundColor: '#f2f2f2',
            selected: false,
            expanded: false,
            order: 0,
          },
        },
      },
      {
        path: 'stock',
        data: {
          menu: {
            title: 'Stock',
            materialIcon: 'shopping_cart',
            materialColor: '#0F9D58',
            cssClass: 'stock',
            selected: false,
            expanded: false,
            order: 100,
          },
        },
      },
      {
        path: 'crossChannel',
        data: {
          menu: {
            title: 'Cross Channel',
            materialIcon: 'loop',
            materialColor: '#D28024',
            cssClass: 'cross-channel',
            selected: false,
            expanded: false,
            order: 200,
          },
        },
      },
      {
        path: 'usage',
        data: {
          menu: {
            title: 'Uso de App',
            materialIcon: 'access_time',
            materialColor: '#4285F4',
            cssClass: 'usage',
            selected: false,
            expanded: false,
            order: 300,
          },
        },
      },
      {
        path: 'management',
        data: {
          menu: {
            title: 'Gestión',
            materialIcon: 'border_color',
            materialColor: '#616161',
            selected: false,
            expanded: false,
            separator: true,
            order: 400,
          },
        },
      },
    ],
  },
];
