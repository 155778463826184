import {Injectable} from '@angular/core';

@Injectable()
export class DateUtilService {

  public secondsTohhmmss(time: number): string {
    let timeDDHHMMSS;
    const days = Math.floor(time / 86400);
    const hours = Math.floor((time - (days * 86400)) / 3600);
    const minutes = Math.floor((time - (days * 86400) - (hours * 3600)) / 60);
    const seconds = Math.round(time - (days * 86400) - (hours * 3600) - (minutes * 60));
    timeDDHHMMSS = (days > 0) ? (days < 10 ? '0' + days : days) + 'd ' : '';
    timeDDHHMMSS += (hours > 0) ? (hours < 10 ? '0' + hours : hours) + 'h ' : '';
    timeDDHHMMSS += (minutes > 0) ? (minutes < 10 ? '0' + minutes : minutes) + 'm ' : '';
    timeDDHHMMSS += (seconds < 10 ? '0' + seconds : seconds) + 's';
    return timeDDHHMMSS;
  }

}
