import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {ReplaySubject, Subscription, Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {User} from '../pages/login';

export class OrganizationType {
  id: string;
  name: string;

  constructor(orgType: OrganizationType = null) {
    if (orgType) {
      this.id = orgType.id;
      this.name = orgType.name;
    }
  }
}

export class EcommerceConfiguration {
  sendOrder: boolean;
  type: string;

  constructor(config: EcommerceConfiguration = null) {
    if (config) {
      this.sendOrder = config.sendOrder;
      this.type = config.type;
    }
  }
}

export class ShopifyConfiguration extends EcommerceConfiguration {
  shopName: string;
  connected: boolean;

  constructor(config: ShopifyConfiguration = null) {
    super(config);
    this.type = 'shopifyConfiguration';
    if (config) {
      this.shopName = config.shopName;
      this.connected = config.connected;
    }
  }
}


export class MagentoConfiguration extends EcommerceConfiguration {
  version: string;
  consumerKey: string;
  consumerSecret: string;
  host: string;
  username: string;
  apiKey: string;

  constructor(config: MagentoConfiguration = null) {
    super();
    this.type = 'magentoConfiguration';
    if (config) {
      this.version = config.version;
      this.consumerKey = config.consumerKey;
      this.consumerSecret = config.consumerSecret;
      this.host = config.host;
      this.username = config.username;
      this.apiKey = config.apiKey;
    }
  }

}

export class PrestashopConfiguration extends EcommerceConfiguration {
  host: string;
  apiKey: string;

  constructor(config: PrestashopConfiguration = null) {
    super();
    this.type = 'prestashopConfiguration';
    if (config) {
      this.host = config.host;
      this.apiKey = config.apiKey;
    }
  }
}

export class OrganizationConfiguration {
  expressCheckout: boolean;
  selfCheckout: boolean;

  constructor(conf: OrganizationConfiguration = null) {
    if (conf) {
      this.expressCheckout = conf.expressCheckout;
      this.selfCheckout = conf.selfCheckout;
    }
  }
}

export class Organization {
  id: string;
  nif: string;
  organization: string;
  phone: string;
  customerEmail: string;
  logoURL: string;
  showcaseURL: string;
  enabled: boolean = false;
  stripeConnected: boolean;
  ecommerceConfiguration: EcommerceConfiguration;
  allowNewAssistant: boolean;
  allowNewShop: boolean;
  organizationType: OrganizationType;
  securityStockage: number;
  organizationName: string;
  configuration: OrganizationConfiguration;

  constructor(org: Organization = null) {
    if (org) {
      this.id = org.id;
      this.nif = org.nif;
      this.organization = org.organization;
      this.phone = org.phone;
      this.customerEmail = org.customerEmail;
      this.logoURL = org.logoURL;
      this.showcaseURL = org.showcaseURL;
      this.enabled = org.enabled;
      this.stripeConnected = org.stripeConnected;
      if (org.ecommerceConfiguration && org.ecommerceConfiguration.type) {
        switch (org.ecommerceConfiguration.type) {
          case 'shopifyConfiguration':
            this.ecommerceConfiguration = new ShopifyConfiguration(<ShopifyConfiguration>org.ecommerceConfiguration);
            break;
          case 'magentoConfiguration':
            this.ecommerceConfiguration = new MagentoConfiguration(<MagentoConfiguration>org.ecommerceConfiguration);
            break;
          case 'prestashopConfiguration':
            this.ecommerceConfiguration = new PrestashopConfiguration(
              <PrestashopConfiguration> org.ecommerceConfiguration);
            break;
        }
      }
      if (org.configuration) {
        this.configuration = new OrganizationConfiguration(<OrganizationConfiguration>org.configuration);
      }
      this.allowNewAssistant = org.allowNewAssistant;
      this.allowNewShop = org.allowNewShop;
      this.organizationType = new OrganizationType(org.organizationType);
      this.securityStockage = org.securityStockage;
    }
  }

}

@Injectable()
export class OrganizationService {

  private organizations: Array<Organization> = [];
  private organizationObservable: ReplaySubject<Array<Organization>> = new ReplaySubject<Array<Organization>>(1);
  private lastOrganizationSubject: ReplaySubject<Organization> = new ReplaySubject(1);
  private requestLive: Boolean = false;

  constructor(private _httpService: ByHttpService, private _oauthService: OAuthService) {
    console.log('Create organization service');
    const user: User = _oauthService.getIdentityClaims() as User;
    if (user && user.roles && user.roles.indexOf('ROLE_ADMIN_ORGANIZATION') >= 0 && user.organizationId) {
      this.lastOrganizationSubject.next(user.organization);
    }
  }

  getOrganizations(organizationId: string = null, refresh: boolean = false,
                   defaultOrganization: Organization = null): Observable<Array<Organization>> {
    if (this.organizations.length > 0 && !refresh) {
      return this.organizationObservable;
    }
    if (!this.requestLive) {
      this.requestLive = true;
      this._httpService.doApiGetConnection<Array<Organization>>(
        `/api/organizations/${organizationId ? organizationId : ''}`)
        .subscribe((doc) => {
          this.requestLive = false;
          this.invalidateOrganizations(organizationId);
          for (let i = 0; i < doc.length; i++) {
            this.organizations.push(doc[i]);
          }
          this.organizationObservable.next(this.organizations);
        }, (doc) => {
          this.requestLive = false;
          if (defaultOrganization) {
            const defaultOrganizationArray = [];
            defaultOrganizationArray.push(defaultOrganization);
            this.organizationObservable.next(defaultOrganizationArray);
          } else {
            this.organizationObservable.error(doc);
          }
        });
    }
    return this.organizationObservable;
  }

  setOrganization(organization: Organization): void {
    this.lastOrganizationSubject.next(organization);
    if (organization) {
      localStorage.setItem('lastOrganizationId', organization.id);
    }
  }

  subscribeOrganization(next?: (value: Organization) => void, error?: (error: any) => void,
                        complete?: () => void): Subscription {
    return this.lastOrganizationSubject.subscribe(next, error, complete);
  }

  saveOrganization(organizationId: string, organization: Organization): Observable<Organization> {
    return Observable.create((subscriber) => {
      const _organization = jQuery.extend(true, {}, organization);
      this.removeOrganizationTypeIfEmpty(_organization);
      this._httpService.doApiPutConnection('/api/organizations/' + organizationId, _organization)
        .subscribe((newOrganization: Organization) => {
          for (let i = 0; i < this.organizations.length; i++) {
            if (newOrganization.id === this.organizations[i].id) {
              this.organizations[i] = newOrganization;
            }
          }
          subscriber.next(newOrganization);
        }, (err) => {
          subscriber.error(err);
        });
    });

  }


  private removeOrganizationTypeIfEmpty(_organization: Organization) {
    if (_organization.organizationType && !_organization.organizationType.id) {
      delete _organization.organizationType;
    }
  }

  private invalidateOrganizations(organizationId: string = null) {
    let i = this.organizations.length;
    while (i--) {
      if (!organizationId || this.organizations[i].id === organizationId) {
        this.organizations.splice(i, 1);
      }
    }
  }
}


