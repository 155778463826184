import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import 'style-loader!./ngxChart.scss';
import {NgxChartConf, ChartParameters} from '../../../services/ngxChartConf';

@Component({
  selector: 'ngx-chart',
  templateUrl: './ngxChart.html',
})
export class NgxChartComponent implements OnDestroy, AfterViewInit {

  private chart: AmChart;
  private configurableParameters: ChartParameters;
  @Input() baAmChartClass: string;
  @Input() ngxChartConf: NgxChartConf;
  @Output() onChartReady = new EventEmitter<AmChart>();

  @ViewChild('baAmChart') public _selector: ElementRef;


  constructor(private AmCharts: AmChartsService) {
  }

  ngAfterViewInit() {
    this.configurableParameters = this.ngxChartConf.getConfParameters();
    this.chart = this.AmCharts.makeChart(this._selector.nativeElement, {
      'type': 'serial',
      'categoryField': 'date',
      'theme': 'light',
      'autoMargins': true,
      'plotAreaBorderColor': '#FFFFFF',
      'dataDateFormat': 'DD/MM/YYYY',
      'graphs': this.configurableParameters.lines,
      'valueAxes': [{
        'axisAlpha': 0,
        'labelFunction': this.configurableParameters.labelFunction,
      }],
      'categoryAxis': {
        'parseDates': true,
        'gridPosition': 'start',
        'gridAlpha': 0,
      },
    });
    this.onChartReady.emit(this.chart);
    this.chart.addListener('rendered', () => {
      this.chart.zoomOut();
    });

  }

  ngOnDestroy() {
    if (this.chart) {
      this.AmCharts.destroyChart(this.chart);
    }
  }

}
