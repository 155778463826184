import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GlobalState} from '../../../global.state';
import 'style-loader!./headerDates.scss';
import {HeaderDateService, RangeDate, SubPageList} from '../../../services/headerDate.service';
import {Subscription} from 'rxjs/Subscription';
import {FormBuilder} from '@angular/forms';
import {BsDaterangepickerConfig, BsLocaleService, defineLocale} from 'ngx-bootstrap';
import {esLocale} from 'ngx-bootstrap';

@Component({
  selector: 'ngx-header-dates',
  templateUrl: 'headerDates.html',
})


export class HeaderDatesComponent implements OnInit, OnChanges {

  fullScreen: boolean = false;
  private subPageList: SubPageList;
  subscriptionRangeDate: Subscription;
  subscriptionSubPages: Subscription;
  headerDateService: HeaderDateService;
  private bsFromValue: Date;
  private bsToValue: Date;
  private bsFromConfig: Partial<BsDaterangepickerConfig>;
  private bsToConfig: Partial<BsDaterangepickerConfig>;
  private lastChangeDueToChangeOfSection: boolean;
  @Input('isManagementSection') isManagementSection: boolean;

  constructor(private _state: GlobalState, _headerDateService: HeaderDateService, private formBuilder: FormBuilder,
              private localeService: BsLocaleService) {
    defineLocale('es', esLocale);
    this.headerDateService = _headerDateService;
    this.localeService.use('es');
    this.bsFromConfig = Object.assign({}, {rangeInputFormat: 'DD MMM, YYYY'});
    this.bsToConfig = Object.assign({}, {rangeInputFormat: 'DD MMM, YYYY'});
    this.lastChangeDueToChangeOfSection = false;
    const maxDate = new Date();
    this.bsFromConfig = Object.assign({}, this.bsFromConfig, {maxDate: maxDate});
    this.bsToConfig = Object.assign({}, this.bsToConfig, {maxDate: maxDate});
    this.subscriptionRangeDate = this.headerDateService.getRangeDate().subscribe(
      rangeDate => {
        this.updateBsValue(rangeDate);
      });
    this.subscriptionSubPages = this.headerDateService.subPageListChanged$.subscribe(
      subPageList => {
        this.subPageList = subPageList;
      });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.isManagementSection.currentValue !== null) {
      this.initDates();
      this.lastChangeDueToChangeOfSection = true;
    }
  }

  ngOnInit() {
    this.bsFromConfig.containerClass = 'theme-default';
    this.bsToConfig.containerClass = 'theme-default';
  }


  updateBsValue(rangeDate: RangeDate) {
      this.bsFromValue = rangeDate.fromDate;
      this.bsToValue = rangeDate.toDate;
  }


  onValueFromChange(event: Date) {
    setTimeout(() => {
      if (event !== null) {
        let maxDate = new Date(event.getTime() + 30 * 86400000);
        maxDate = maxDate.getTime() > new Date().getTime() ? new Date() : maxDate;
        this.bsToConfig = Object.assign({}, this.bsToConfig, {maxDate: maxDate});
        this.bsToConfig = Object.assign({}, this.bsToConfig, {minDate: this.bsFromValue});
      }
    });
  }

  onHiddenFrom() {
    setTimeout(() => {
      document.getElementById('calendar-to-btn').click();
    });
    this.lastChangeDueToChangeOfSection = false;
  }

  onValueToChange(event: Date) {
    if (this.bsFromValue !== null && event !== null) {
      this.bsFromValue.setHours(0, 0, 0, 0);
      event.setHours(23, 59, 59, 0);
      const range = [this.bsFromValue, event];
      if (range[0] !== null && range[1] !== null && !this.lastChangeDueToChangeOfSection) {
        if (range[1].getMonth() - range[0].getMonth() > 0 && range[1].getDate() - range[0].getDate() > 0
          || range[1].getMonth() > new Date().getMonth()
          || (range[1].getMonth() === new Date().getMonth() && range[1].getDate() > new Date().getDate())
          || range[0].getTime() > range[1].getTime()) {
          document.getElementById('input-to-date').setAttribute('style', 'color:#CF2333;');
          console.log('Please enter a valid dates range, max 1 month, and maximum date is today');
        } else {
          this.headerDateService.newDate(new RangeDate(range[0], range[1]));
          document.getElementById('input-to-date').setAttribute('style', 'color:#000;');
        }
      }
    }
  }

  initDates() {
    const range = [this.bsFromValue, this.bsToValue];
    if (this.bsFromValue === undefined || this.bsToValue === undefined) {
      if (this.isManagementSection) {
        this.headerDateService.initDates('management');
      } else if (!this.isManagementSection) {
        this.headerDateService.initDates('analytics');
      }
    } else {
      if (this.isManagementSection
        && this.compareRangesDateWithoutHHMMSS(range, this.getLastMonthRangeForAnalytics())) {
        this.headerDateService.initDates('management');
      } else if (!this.isManagementSection
        && this.compareRangesDateWithoutHHMMSS(range, this.getLastMonthRangeForManagement())) {
        this.headerDateService.initDates('analytics');
      }
    }
  }

  getLastMonthRangeForAnalytics(): Date[] {
    const previousDate: Date = new Date();
    const currentDate: Date = new Date();
    previousDate.setMonth(new Date().getMonth() - 1);
    previousDate.setDate(new Date().getDate());
    currentDate.setDate(currentDate.getDate() - 1);
    return [previousDate, currentDate];
  }

  getLastMonthRangeForManagement(): Date[] {
    const previousDate: Date = new Date();
    const currentDate: Date = new Date();
    previousDate.setMonth(new Date().getMonth() - 1);
    previousDate.setDate(new Date().getDate());
    return [previousDate, currentDate];
  }

  compareRangesDateWithoutHHMMSS(range1: Date[], range2: Date[]): boolean {
    return range1[0].getDate() === range2[0].getDate() && range1[0].getMonth() === range2[0].getMonth()
      && range1[0].getFullYear() === range2[0].getFullYear() && range1[1].getDate() === range2[1].getDate()
      && range1[1].getMonth() === range2[1].getMonth()
      && range1[1].getFullYear() === range2[1].getFullYear();
  }
}
