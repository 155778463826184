import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

@Component({
  selector: 'ngx-google-maps',
  styleUrls: ['./googleMaps.scss'],
  templateUrl: './googleMaps.html',
})
export class GoogleMapsComponent implements OnInit, OnChanges {

  // google maps zoom level
  zoom: number = 14;

  // initial center position for the map

  searchElementLoaded: boolean = false;

  @Input() lat: number = 41.3870194;
  @Input() lng: number = 2.1678584;
  @Input() searchElementRef: ElementRef;
  @Output() onNewCoordinates = new EventEmitter<Marker>();

  marker: Marker;

  constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) {
  }

  public setMarker(_marker: Marker) {
    this.lat = _marker.lat;
    this.lng = _marker.lng;
    this.marker = _marker;
    this.zoom = 16;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchElementRef'] && this.searchElementRef && !this.searchElementLoaded) {
      this.searchElementLoaded = true;
      this.mapsAPILoader.load().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: ['establishment'],
        });
        autocomplete.addListener('place_changed', () => {
          this.ngZone.run(() => {
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();

            if (place.geometry === undefined || place.geometry === null) {
              return;
            }

            this.setMarker({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              draggable: true,
              address: place.address_components,
              phone: place.international_phone_number,
            });

            this.onNewCoordinates.emit(this.marker);
          });
        });
      });
    }
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      if (!this.lat && !this.lng) {
        this.setCurrentPosition();
      }
    });
  }

  public mapClicked($event: MouseEvent) {
    console.log('clicked the marker: ' + (<any>$event).coords.lat + ', long:' + (<any>$event).coords.lng);
    this.lat = (<any>$event).coords.lat;
    this.lng = (<any>$event).coords.lng;
    this.marker = {
      lat: this.lat,
      lng: this.lng,
      draggable: true,
    };

    this.onNewCoordinates.emit(this.marker);
  }

  public markerDragEnd(m: Marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setMarker({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          draggable: true,
        });
        this.zoom = 16;
      });
    }
  }

}

// just an interface for type safety.
export interface Marker {
  lat: number;
  lng: number;
  draggable: boolean;
  address?: GeocoderAddressComponent[];
  phone?: string;
}
