import {AfterViewInit, Component, Inject} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import {GlobalState} from './global.state';
import {BaThemePreloader, BaThemeSpinner} from './theme/services';
import {BaThemeConfig} from './theme';
import 'style-loader!./app.scss';
import 'style-loader!./theme/initial.scss';
import {Router} from '@angular/router';
import {ApiConfig} from './app-config';
import {APP_CONFIG} from 'app/pages/app.config';
import set = Reflect.set;

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'ngx-app',
  template: `
    <main [ngClass]="{'full-screen': fullScreen, 'menu-collapsed': isMenuCollapsed}"
          [style.background-color]="backgroundColor" ngxBaThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>
  `,
})
export class AppComponent implements AfterViewInit {

  isMenuCollapsed: boolean = false;
  fullScreen: boolean = false;
  backgroundColor: string = 'var(--white)';
  menuItem: any;

  constructor(private _state: GlobalState,
              private _spinner: BaThemeSpinner,
              private themeConfig: BaThemeConfig,
              private _oauthService: OAuthService,
              private _router: Router,
              @Inject(APP_CONFIG) private config: ApiConfig) {

    themeConfig.config();

    this._configOauth2(_oauthService);
  }

  public ngAfterViewInit(): void {
    // hide spinner once all loaders are completed
    BaThemePreloader.load()
      .then((values) => {
        this._spinner.hide();
        setTimeout(() => {
          this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
          });
          this._state.subscribe('fullScreen', (currentItem) => {
            this.fullScreen = currentItem;
            this.calcBackgroundColor();
          });
          this._state.subscribe('menu.activeLink', (currentItem) => {
            this.menuItem = currentItem;
            this.calcBackgroundColor();
          });
        });
      }).catch((err) => {
      this._router.navigate(['login']);
    });

  }

  private calcBackgroundColor() {
    if (this.fullScreen) {
      this.backgroundColor = '#f2f2f2';
    } else {
      if (this.menuItem && this.menuItem.backgroundColor) {
        this.backgroundColor = this.menuItem.backgroundColor;
      } else {
        this.backgroundColor = '#ffffff';
      }
    }
  }

  private _configOauth2(_oauthService: OAuthService): void {
    // Login-Url
    console.log('Configuring oauth2');

    _oauthService.tokenEndpoint = `${this.config.getBaseUrl()}/oauth/token`;

    _oauthService.userinfoEndpoint = `${this.config.getBaseUrl()}/api/userInfo`;

    // The SPA's id. Register SPA with this id at the auth-server
    _oauthService.clientId = this.config.APP_ID;

    _oauthService.dummyClientSecret = this.config.APP_SECRET;

    // set the scope for the permissions the client should request
    _oauthService.scope = this.config.APP_ROLE;

    // set to true, to receive also an id_token via OpenId Connect (OIDC) in addition to the
    // OAuth2-based access_token
    _oauthService.oidc = false;

    _oauthService.setStorage(localStorage);

  };


}
