import {Component} from '@angular/core';
import {SellService} from '../../../services/sells.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {User} from '../../../pages/login';
import {DefaultLocale, LocaleService} from 'angular-l10n';

@Component({
  selector: 'ngx-ba-msg-center',
  styleUrls: ['./baMsgCenter.scss'],
  templateUrl: './baMsgCenter.html',
})
export class BaMsgCenterComponent {

  @DefaultLocale() defaultLocale: string;

  public sells: Array<any> = [];

  public showMessageCenter: boolean = false;

  constructor(private _sellService: SellService, private _oauthService: OAuthService,
              private localeService: LocaleService) {
    const user = _oauthService.getIdentityClaims() as User;
    if (user && user.roles && user.roles.indexOf('ROLE_ADMIN_ORGANIZATION') >= 0) {
      this.sells = this._sellService.sellsUnread;
      this.showMessageCenter = true;
    }
  }

  viewSells() {
    this._sellService.resetUnreadSells();
  }

}
