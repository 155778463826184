import {Injectable} from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _oauthService: OAuthService, private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin(url: string): Observable<boolean> {
    console.log('Check login');
    const result = new ReplaySubject<boolean>(1);
    if (!this._oauthService.hasValidAccessToken()) {
      if (!this._oauthService.getRefreshToken()) {
        this._oauthService.logOut(true);
        this._router.navigate(['login']);
        result.next(false);
      } else {
        this._oauthService.refreshToken()
          .then((doc) => {
            console.log('Refresh token ok!');
            result.next(true);
          })
          .catch((err) => {
            console.log('Refresh token ko, redirect to login!');
            this._oauthService.logOut(true);
            this._router.navigate(['login']);
            result.next(false);
          });
      }
    } else {
      result.next(true);
    }
    return result;
  }
}
