import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import 'style-loader!./graphDigitalOffline.scss';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {DigitalOfflineData} from '../../../services/digitalOffline.service';
import {NgxChartConf, ChartLineParameters, ChartParameters} from '../../../services/ngxChartConf';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ngx-graph-digital-offline',
  templateUrl: './graphDigitalOffline.html',
})
export class GraphDigitalOfflineComponent implements OnInit, OnChanges {



  private digitalOfflineChart: AmChart;
  shopId: string;

  private chartConf: NgxChartConf = new NgxChartConf();
  @Input('digitalOfflineData') digitalOfflineData: DigitalOfflineData;

  constructor(private AmCharts: AmChartsService, private route: ActivatedRoute) {

    const _labelFunction = (value, valueString, axis) => {
      if (value === 0) {
        return valueString;
      } else {
        return '' + valueString;
      }
    };
    const _balloonFunction = (graphDataItem, graph) => {
      return graphDataItem.values.value.toString();
    };
    const _lines = [];
    _lines.push(new ChartLineParameters('m', 'standard', '#a37eff', 'standard', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'expressShipping', '#8F8F8F',
      'expressShipping', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'shopPickup', '#F28773', 'shopPickup', 'round', _balloonFunction));
    this.chartConf.setConfParameters(new ChartParameters(_lines, _labelFunction));

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.shopId = params['id'];
    });
  }

  public setDigitalOfflineChart(_digitalOfflineChart: any) {
    this.digitalOfflineChart = _digitalOfflineChart;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.digitalOfflineData.currentValue) {
      this.digitalOfflineData = changes.digitalOfflineData.currentValue;
      this.refreshChart()
    }
  }
  private refreshChart() {
    if (this.digitalOfflineChart && this.digitalOfflineData) {
      this.AmCharts.updateChart(this.digitalOfflineChart, () => {
        this.digitalOfflineChart.dataProvider = this.digitalOfflineData.dateDigitalOffline;
      });
    }
  }

}
