import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ngx-last-syncs',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./lastSyncs.scss'],
  templateUrl: './lastSyncs.html',
})
export class LastSyncsComponent {

  @Input() lastSyncs: Array<any> = null;

}
