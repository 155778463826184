import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import 'style-loader!./graphReservations.scss';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {PreOrderData} from '../../../services/preOrder.service';
import {ActivatedRoute} from '@angular/router';
import {NgxChartConf, ChartLineParameters, ChartParameters} from '../../../services/ngxChartConf';


@Component({
  selector: 'ngx-graph-reservations',
  templateUrl: './graphReservations.html',
})
export class GraphReservationsComponent implements OnInit, OnChanges {


  private preOrderChart: AmChart;
  shopId: string;

  private chartConf: NgxChartConf = new NgxChartConf();
  @Input('preOrderData') preOrderData: PreOrderData;

  constructor(private route: ActivatedRoute, private AmCharts: AmChartsService) {
    const _labelFunction = (value, valueString, axis) => {
      if (value === 0) {
        return valueString;
      } else {
        return '' + valueString;
      }
    };
    const _balloonFunction = (graphDataItem, graph) => {
      return graphDataItem.values.value.toString();
    };

    const _lines = [];
    _lines.push(new ChartLineParameters('m', 'received', '#a37eff', 'received', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'converted', '#8F8F8F', 'converted', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'rejected', '#F28773', 'rejected', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'cancelled', '#A3F6F4', 'cancelled', 'round', _balloonFunction));
    this.chartConf.setConfParameters(new ChartParameters(_lines, _labelFunction));
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.shopId = params['id'];
    });
  }


  public setPreOrderChart(_preOrderChart: any) {
    this.preOrderChart = _preOrderChart;
  }

  private refreshChart() {
    if (this.preOrderChart && this.preOrderData) {
      this.AmCharts.updateChart(this.preOrderChart, () => {
        this.preOrderChart.dataProvider = this.preOrderData.datePreOrder;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preOrderData.currentValue) {
      this.preOrderData = changes.preOrderData.currentValue;
      this.refreshChart();
    }
  }

}
