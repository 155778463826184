import {Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {ByHttpService} from './http.services';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {map} from 'rxjs/operators';


export class AppUsageData {
  usageTime: number = 0;
  dateUsage: Array<AppUsageChartData> = [];
  shops: Array<ShopUsageData> = [];

  get usage(): number {
    return Math.floor(this.usageTime / 3600);
  }

}

export class AppUsageChartData {
  constructor(_date: Date, _duration: number) {
    this.date = _date;
    this.duration = _duration;
  }

  date: Date;
  duration: number;
}

export class ShopUsageData {

  constructor(_shopName: string, _usageTime: number, _shopId: string, _shopCode: string) {
    this.shopName = _shopName;
    this.usageTime = _usageTime;
    this.shopId = _shopId;
    this.shopCode = _shopCode;
  }
  shopId: string;
  shopName: string;
  shopCode: string;
  usageTime: number;
}

export class ShopUsageDateData {

  shopId: string;
  date: string;
  usageTime: number;
}

@Injectable()
export class AppUsageService {

  private appUsageObservable: ReplaySubject<[Shop[], ShopUsageDateData[]]> = new ReplaySubject(1);
  private lastAppUsageData: string;

  constructor(private _shopService: ShopService, private _httpService: ByHttpService) {
  }

  public getAppUsage(organizationId: string, from: Date, to: Date, shopId: string | null = null):
    Observable<AppUsageData> {
    const request = {
      'from': from,
      'to': to,
    };
    const nextAppUsageData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
    if (nextAppUsageData !== this.lastAppUsageData) {
      this.lastAppUsageData = nextAppUsageData;
      zip(this._shopService.getShops(organizationId, true),
        this._httpService.doApiPostConnection<[ShopUsageDateData]>('/api/analytics/usage', request, true),
      ).subscribe((next) => {
        this.appUsageObservable.next(next)
      })
    }

    return this.appUsageObservable.pipe(
      map<[Shop[], ShopUsageDateData[]], AppUsageData>((result) => {
        const shops = result[0];
        const usageData = result[1];

        const appUsageData = new AppUsageData();

        appUsageData.usageTime = usageData.filter(shopDate => shopId ? shopDate.shopId === shopId : true)
          .map(shopDate => shopDate.usageTime)
          .reduce((a, b) => a + b, 0);

        let date = from;
        while (date <= to) {
          const usageTime = usageData.filter(shopDate => shopDate.date === this.formatDate(date))
            .filter(shopDate => shopId ? shopDate.shopId === shopId : true)
            .map(shopDate => shopDate.usageTime)
            .reduce((a, b) => a + b, 0) / 60;
          appUsageData.dateUsage.push(new AppUsageChartData(date, usageTime));
          date = this.nextDate(date);
        }

        appUsageData.shops = [];
        shops.filter(shop => usageData.map(data => data.shopId).indexOf(shop.id) !== -1).forEach(shop => {
          const usageTime = usageData.filter(shopDate => shopDate.shopId === shop.id)
            .map(shopDate => shopDate.usageTime)
            .reduce((a, b) => a + b, 0);
          appUsageData.shops.push(new ShopUsageData(shop.name, usageTime, shop.id, shop.posId));
        });
        return appUsageData;
      }),
    );
  }


  public getUsageDataChart(fromDate: Date, appUsageData: AppUsageData = null): any {
    return appUsageData.usageTime;
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }


}
