import {Component, ViewEncapsulation, Input} from '@angular/core';

@Component({
  selector: 'ngx-result-import',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./resultImport.scss'],
  templateUrl: './resultImport.html',
})
export class ResultImportComponent {

  @Input() resultImport: any = null;

  constructor() {
  }


}
