import {Component, OnDestroy, OnInit} from '@angular/core';
import {Routes} from '@angular/router';

import {BaMenuService} from '../theme';
import {PAGES_MENU} from './pages.menu';
import {Subscription} from 'rxjs';
import {OrganizationService} from '../services/organization.service';
import {GlobalState} from '../global.state';

@Component({
  selector: 'ngx-pages',
  template: `
    <ngx-ba-sidebar *ngIf="fullScreen == false"></ngx-ba-sidebar>
    <ngx-ba-page-top></ngx-ba-page-top>
    <div class="al-main" [class.full-screen]="fullScreen">
      <div class="al-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <ngx-ba-back-top position="200"></ngx-ba-back-top>
  `,
})
export class PagesComponent implements OnInit, OnDestroy {

  private organizationSubscription: Subscription;
  fullScreen: boolean = false;

  constructor(private _menuService: BaMenuService, private _organizationService: OrganizationService,
              private _state: GlobalState) {
  }

  subscribeOrganization() {
    this.organizationSubscription = this._organizationService.subscribeOrganization((organization) => {
      this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU, organization);
    });
    this._state.subscribe('fullScreen', (currentItem) => {
      this.fullScreen = currentItem;
    });
  }

  ngOnInit() {
    this.subscribeOrganization();
  }

  ngOnDestroy() {
    this.organizationSubscription.unsubscribe();
  }

}
