import {Component, Input, OnChanges} from '@angular/core';
import 'style-loader!./graphClickCollect.scss';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {SimpleChanges} from '@angular/core/src/metadata/lifecycle_hooks';
import {ClickCollectData} from '../../../services/clickCollect.service';
import {DateUtilService} from '../../../services/dateUtil.service';
import {NgxChartConf, ChartLineParameters, ChartParameters} from '../../../services/ngxChartConf';


@Component({
  selector: 'ngx-graph-click-collect',
  templateUrl: './graphClickCollect.html',
})
export class GraphClickCollectComponent implements OnChanges {


  private clickCollectChart: AmChart;
  @Input('clickCollectData') clickCollectData: ClickCollectData;

  private chartConf: NgxChartConf = new NgxChartConf();

  constructor(private AmCharts: AmChartsService, private dateUtil: DateUtilService) {
    const _labelFunction = (value, valueString, axis) => {
      if (value === 0) {
        return valueString;
      } else {
        return '' + valueString;
      }
    };
    const _balloonFunction = (graphDataItem, graph) => {
      return graphDataItem.values.value.toString();
    };
    const _lines = [];
    _lines.push(new ChartLineParameters('m', 'received', '#a37eff', 'received', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'completed', '#8F8F8F', 'completed', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'partial', '#F28773', 'partial', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'cancelled', '#A3F6F4', 'cancelled', 'round', _balloonFunction));
    this.chartConf.setConfParameters(new ChartParameters(_lines, _labelFunction));
  }

  secondsTohhmmss(time: number): string {
    return this.dateUtil.secondsTohhmmss(time);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clickCollectData && changes.clickCollectData.currentValue) {
      this.refreshChart();
    }
  }


  public setClickCollectChart(_clickCollectChart: any) {
    this.clickCollectChart = _clickCollectChart;
  }

  private refreshChart() {
    if (this.clickCollectChart && this.clickCollectData) {
      this.AmCharts.updateChart(this.clickCollectChart, () => {
        this.clickCollectChart.dataProvider = this.clickCollectData.dateClickCollect;
      });
    }
  }

}
