export class ChartParameters {
  constructor (_lines: Array<ChartLineParameters>, _labelFunction) {
    this.lines = _lines;
    this.labelFunction = _labelFunction;
  }
  lines: Array<ChartLineParameters> = [];
  labelFunction;
}
export class ChartLineParameters {
  title: string;
  legendValueText: string;
  lineColor: string;
  valueField: string;
  bullet: string;
  balloonFunction;
  constructor (_legendValueText: string, _title: string, _lineColor: string, _valueField: string,
               _bullet: string, _balloonFunction) {
    this.legendValueText = _legendValueText;
    this.title = _title;
    this.lineColor = _lineColor;
    this.valueField = _valueField;
    this.bullet = _bullet;
    this.balloonFunction = _balloonFunction;
  }
}

export class NgxChartConf {
  private confParameters: ChartParameters;

  getConfParameters(): ChartParameters {
    return this.confParameters;
  }

  setConfParameters(_confParameters: ChartParameters) {
    this.confParameters = _confParameters;
  }

}
