import {Component, OnDestroy, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import 'style-loader!./graphStock.scss';
import {AmChart, AmChartsService} from '@amcharts/amcharts3-angular';
import {ActivatedRoute} from '@angular/router';
import {NgxChartConf, ChartLineParameters, ChartParameters} from '../../../services/ngxChartConf';
import {StockData, StockService} from '../../../services/stock.service';


@Component({
  selector: 'ngx-graph-stock',
  templateUrl: './graphStock.html',
})
export class GraphStockComponent implements OnInit, OnChanges {




  private stockChart: AmChart;
  shopId: string;
  @Input('shopsOrProducts') shopsOrProducts: string;
  @Input('stockData') stockData: StockData;
  private chartConf: NgxChartConf = new NgxChartConf();

  constructor(private route: ActivatedRoute,
              private stockDataService: StockService, private AmCharts: AmChartsService) {
    const _labelFunction = (value, valueString, axis) => {
      if (value === 0) {
        return valueString;
      } else {
        return '' + valueString;
      }
    };
    const _balloonFunction = (graphDataItem, graph) => {
      return graphDataItem.values.value.toString();
    };

    const _lines = [];
    _lines.push(new ChartLineParameters('m', 'online', '#a37eff', 'online', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'myShop', '#8F8F8F', 'myShop', 'round', _balloonFunction));
    _lines.push(new ChartLineParameters('m', 'otherShops', '#F28773', 'otherShops', 'round', _balloonFunction));
    this.chartConf.setConfParameters(new ChartParameters(_lines, _labelFunction));
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.shopId = params['id'];
    });
  }

  public setStockChart(_stockChart: any) {
    this.stockChart = _stockChart;
    if (this.stockData) {
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stockData.currentValue) {
      this.stockData = changes.stockData.currentValue;
      this.refreshChart();
    }
  }

  private refreshChart() {
    if (this.stockData && this.stockChart) {
      this.AmCharts.updateChart(this.stockChart, () => {
        this.stockChart.dataProvider = this.stockData.dateStock;
      });
    }
  }

}
