import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ByHttpService} from './http.services';
import {ShopService} from './shops.service';
import {SellService} from './sells.service';
import {UserService} from './user.service';
import {AuthGuard} from './auth-guard.services';
import {OrganizationService} from './organization.service';
import {AdminGuard} from './admin-auth.services';
import {AppUsageService} from './appUsage.service';
import {PreOrderDataService} from './preOrder.service';
import {DigitalOfflineDataService} from './digitalOffline.service';
import {DateUtilService} from './dateUtil.service';
import {HeaderDateService} from './headerDate.service';
import {ClickCollectDataService} from './clickCollect.service';
import {SFSDataService} from './sFSData.service';
import {AppBenefitsDataService} from './appBenefits.service';
import {StockService} from './stock.service';
import {CorssChannelService} from './crossChannel.service';

@NgModule({
  declarations: [],
  imports: [
    // will provide service
    CommonModule,
  ],
  providers: [
    ByHttpService,
    ShopService,
    SellService,
    UserService,
    AuthGuard,
    AdminGuard,
    OrganizationService,
    AppUsageService,
    PreOrderDataService,
    DigitalOfflineDataService,
    ClickCollectDataService,
    DateUtilService,
    HeaderDateService,
    SFSDataService,
    AppBenefitsDataService,
    StockService,
    CorssChannelService,
  ],
  bootstrap: [],
})
export class ApiModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        ByHttpService,
        ShopService,
        SellService,
        UserService,
        AuthGuard,
        AdminGuard,
        OrganizationService,
        AppUsageService,
        PreOrderDataService,
        DigitalOfflineDataService,
        ClickCollectDataService,
        DateUtilService,
        HeaderDateService,
        SFSDataService,
        AppBenefitsDataService,
        StockService,
        CorssChannelService,
      ],
    };
  }

}
