import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class BaImageLoaderService {

  public load(src): Observable<any> {
    return Observable.create((subscriber) => {
      const img = new Image();
      img.src = src;
      img.onload = function () {
        subscriber.next('Image with src ' + src + ' loaded successfully.');
      };
    });
  }
}
