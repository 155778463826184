import {Injectable} from '@angular/core';
import {Router, Routes} from '@angular/router';
import * as _ from 'lodash';

import {BehaviorSubject} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {User} from '../../../pages/login';
import {Organization} from '../../../services/organization.service';

@Injectable()
export class BaMenuService {
  menuItems = new BehaviorSubject<any[]>([]);

  protected _currentMenuItem = {};
  private user: User;

  constructor(private _router: Router, private _oauthService: OAuthService) {
    this.user = _oauthService.getIdentityClaims() as User;
  }

  /**
   * Updates the routes in the menu
   *
   * @param {Routes} routes Type compatible with app.menu.ts
   * @param organization Organization
   */
  public updateMenuByRoutes(routes: Routes, organization: Organization) {
    const convertedRoutes = this.convertRoutesToMenus(organization, _.cloneDeep(routes));
    this.menuItems.next(convertedRoutes);
  }

  public convertRoutesToMenus(organization: Organization, routes: Routes): any[] {
    const items = this._convertArrayToItems(organization, routes);
    return this._skipEmpty(items);
  }

  public getCurrentItem(): any {
    return this._currentMenuItem;
  }

  public selectMenuItem(menuItems: any[]): any[] {
    const items = [];
    menuItems.forEach((item) => {
      this._selectItem(item);

      if (item.selected) {
        this._currentMenuItem = item;
      }

      if (item.children && item.children.length > 0) {
        item.children = this.selectMenuItem(item.children);
      }
      items.push(item);
    });
    return items;
  }

  protected _skipEmpty(items: any[]): any[] {
    const menu = [];
    items.forEach((item) => {
      let menuItem;
      if (item.skip) {
        if (item.children && item.children.length > 0) {
          menuItem = item.children;
        }
      } else {
        menuItem = item;
      }

      if (menuItem) {
        menu.push(menuItem);
      }
    });

    return [].concat.apply([], menu);
  }

  protected _convertArrayToItems(organization: Organization, routes: any[], parent?: any): any[] {
    const items = [];
    routes.forEach((route) => {
      if (route.path === 'stripe') {
        if (organization && !organization.stripeConnected) {
          items.push(this._convertObjectToItem(organization, route, parent));
        }
        return;
      } else if (route.path === 'organizations' || route.path === 'products' || route.path === 'brands' ||
        route.path === 'ecommerce' || route.path === 'stripe') {
        if (this.checkRole('ROLE_ADMIN')) {
          items.push(this._convertObjectToItem(organization, route, parent));
        }
        return;
      } else if (route.path === 'shops') {
        if (!this.checkRole('ROLE_ADMIN') &&
          (!this.checkRole('ROLE_ADMIN_ORGANIZATION') || !organization.allowNewShop)) {
          route.children = null;
          items.push(this._convertObjectToItem(organization, route, parent));
          return;
        }
      } else if (route.path === 'users') {
        if (!this.checkRole('ROLE_ADMIN') &&
          (!this.checkRole('ROLE_ADMIN_ORGANIZATION') || !organization.allowNewAssistant)) {
          route.children = null;
          items.push(this._convertObjectToItem(organization, route, parent));
          return;
        }
      }
      items.push(this._convertObjectToItem(organization, route, parent));
    });
    return items;
  }

  protected checkRole(role: string): boolean {
    return (this.user && this.user.roles && this.user.roles.indexOf(role) >= 0);
  }

  protected _convertObjectToItem(organization: Organization, object, parent?: any): any {
    let item: any = {};
    if (object.data && object.data.menu) {
      // this is a menu object
      item = object.data.menu;
      item.route = object;
      delete item.route.data.menu;
    } else {
      item.route = object;
      item.skip = true;
    }

    // we have to collect all paths to correctly build the url then
    if (Array.isArray(item.route.path)) {
      item.route.paths = item.route.path;
    } else {
      item.route.paths = parent && parent.route && parent.route.paths ? parent.route.paths.slice(0) : ['/'];
      if (!!item.route.path) item.route.paths.push(item.route.path);
    }

    if (object.children && object.children.length > 0) {
      item.children = this._convertArrayToItems(organization, object.children, item);
    }

    const prepared = this._prepareItem(item);

    // if current item is selected or expanded - then parent is expanded too
    if ((prepared.selected || prepared.expanded) && parent) {
      parent.expanded = true;
    }

    return prepared;
  }

  protected _prepareItem(object: any): any {
    if (!object.skip) {
      object.target = object.target || '';
      object.pathMatch = object.pathMatch || 'full';
      return this._selectItem(object);
    }

    return object;
  }

  protected _selectItem(object: any): any {
    if ((object.route.path === 'management'
      || object.route.path === 'crossChannel'
      || object.route.path === 'stock'
      || object.route.path === 'organizations')
      && !object.route.children) {
      object.selected = this._router.isActive(this._router.createUrlTree(object.route.paths), false);
    } else {
      object.selected = this._router.isActive(this._router.createUrlTree(object.route.paths),
        object.pathMatch === 'full');
    }
    return object;
  }
}
