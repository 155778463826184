import {throwError as observableThrowError, Observable} from 'rxjs';
/**
 * Created by el_fl on 02/02/2017.
 */
import {Inject, Injectable} from '@angular/core';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {OAuthService} from 'angular-oauth2-oidc';
import {ApiConfig} from '../app-config';
import {APP_CONFIG} from '../pages/app.config';

enum TypeConnection {
  GET,
  POST,
  PUT,
  DELETE,
}


@Injectable()
export class ByHttpService {

  clientAccessToken: any;

  constructor(private http: HttpClient, private _oauthService: OAuthService,
              @Inject(APP_CONFIG) private config: ApiConfig) {
  }

  public doApiGetConnection<T>(url: string = null, authenticated: boolean = true): Observable<T> {
    return this.doApiConnection<T>(TypeConnection.GET, url, null, authenticated);
  }

  public doApiPutConnection<T>(url: string = null, body = null, authenticated: boolean = true): Observable<T> {
    return this.doApiConnection<T>(TypeConnection.PUT, url, body, authenticated);
  }

  public doApiDeleteConnection<T>(url: string = null, authenticated: boolean = true): Observable<T> {
    return this.doApiConnection<T>(TypeConnection.DELETE, url, null, authenticated);
  }

  public doApiPostConnection<T>(url: string = null, body = null, authenticated: boolean = true): Observable<T> {
    return this.doApiConnection<T>(TypeConnection.POST, url, body, authenticated);
  }

  private doApiConnection<T>(type: TypeConnection, url: string, body = null, authenticated: boolean): Observable<T> {
    if (authenticated && !this._oauthService.hasValidAccessToken() &&
      !this._oauthService.getRefreshToken()) {
      this._oauthService.logOut(false);
    } else {
      if (!this.hasValidAccessToken(authenticated)) {
        return this.getToken(authenticated).flatMap((doc) => {
          return this.doDirectApiConnection<T>(type, url, body, authenticated);
        })
      } else {
        return this.doDirectApiConnection<T>(type, url, body, authenticated);
      }
    }
  }

  private getToken(authenticated: boolean): Observable<any> {
    if (authenticated) {
      return Observable.fromPromise(this._oauthService.refreshToken());
    } else {
      return this.getClientAccessToken();
    }
  }

  private doDirectApiConnection<T>(type: TypeConnection, url: string = null, body = null, authenticated: boolean):
    Observable<T> {

    let accessToken: String;
    if (authenticated) {
      accessToken = this._oauthService.getAccessToken();
    } else {
      accessToken = this.clientAccessToken.access_token;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    });

    const backendUrl: string = this.config.getBaseUrl() + url;

    console.log(`Http call ${backendUrl}`);

    return this.makeConnection<T>(backendUrl, headers, type, body)
  }

  private handleError(error: Response | any) {
    // In a real world app, you might use a remote logging infrastructure
    let errMsg: string;
    if (error instanceof Response) {
      errMsg = `${error.status} - ${error.text() || ''}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return observableThrowError(error);
  }

  private makeConnection<T>(backendUrl, headers, type: TypeConnection, body = null): Observable<T> {
    switch (type) {
      case TypeConnection.GET:
        return this.http.get<T>(backendUrl, {headers: headers});
      case TypeConnection.POST:
        return this.http.post<T>(backendUrl, body, {headers: headers});
      case TypeConnection.PUT:
        return this.http.put<T>(backendUrl, body, {headers: headers});
      case TypeConnection.DELETE:
        return this.http.delete<T>(backendUrl, {headers: headers});
      default:
        return new Observable(observer => {
          observer.error('Method unknown: ' + type);
        });

    }
  }

  private getClientAccessToken(): Observable<any> {
    const search = new URLSearchParams();
    search.set('grant_type', 'client_credentials');
    search.set('client_id', 'clientapi');
    search.set('scope', 'ROLE_APP');
    search.set('client_secret', 'YX8LXjy4UjRBhsEJAaxQA8hh');

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');

    const params = search.toString();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http.post(this._oauthService.tokenEndpoint, params, httpOptions).map(
      (tokenResponse) => {
        this.clientAccessToken = tokenResponse;
        return tokenResponse;
      });

  }

  private hasValidAccessToken(authenticated: boolean) {
    if (authenticated) {
      return this._oauthService.hasValidAccessToken();
    } else {
      if (this.clientAccessToken && this.clientAccessToken.access_token) {
        const expiresAt = this.clientAccessToken.expires_at;
        const now = new Date();
        return expiresAt && parseInt(expiresAt, 10) < now.getTime();
      }

      return false;
    }
  }
}
