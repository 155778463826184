import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {map} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';

export class PreOrderData {
  received: number;
  converted: number;
  rejected: number;
  cancelled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
  shops: Array<PreOrderShopData> = [];
  datePreOrder: Array<PreOrderChartData> = [];
}

export class PreOrderByShopData {
  received: number;
  converted: number;
  rejected: number;
  cancelled: number;
  products: Array<PreOrderProductData> = [];
  datePreOrder: Array<PreOrderChartData> = [];
}

export class PreOrderChartData {
  constructor(_date: Date, _received: number, _converted: number, _rejected: number, _cancelled: number) {
    this.date = _date;
    this.received = _received;
    this.converted = _converted;
    this.rejected = _rejected;
    this.cancelled = _cancelled;
  }

  date: Date;
  received: number;
  converted: number;
  rejected: number;
  cancelled: number;
}

export class PreOrderAnalyticsResultDto {
  id: PreOrderAnalyticsResultIdentifierDto;
  total: number;
  open: number;
  accepted: number;
  rejected: number;
  expired: number;
  converted: number;
  avgCheckTime: number;
  avgDeliveryTime: number;
}

export class PreOrderAnalyticsResultIdentifierDto {
  shopId: String;
  date: number;
}

class PreOrderAnalyticsByShopResultDto {
  id: PreOrderAnalyticsByShopResultIdentifierDto;
  total: number;
  open: number;
  accepted: number;
  rejected: number;
  expired: number;
  converted: number;
  avgCheckTime: number;
  avgDeliveryTime: number;
}

export class PreOrderAnalyticsByShopResultIdentifierDto {
  reference: string;
  variant: string;
  date: number;
  barcode: string;
}

export class PreOrderShopData {
  constructor(_received: number, _converted: number, _rejected: number, _cancelled: number,
              _shopName: string, _shopId: string, _shopCode: string, _avgDeliveryTime: number, _avgCheckTime: number) {
    this.received = _received;
    this.converted = _converted;
    this.rejected = _rejected;
    this.cancelled = _cancelled;
    this.shopName = _shopName;
    this.shopCode = _shopCode;
    this.shopId = _shopId;
    this.avgDeliveryTime = _avgDeliveryTime;
    this.avgCheckTime = _avgCheckTime;
  }

  shopId: string;
  shopName: string;
  shopCode: string;
  received: number;
  converted: number;
  rejected: number;
  cancelled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
}

export class PreOrderProductData {
  constructor(_received: number, _converted: number, _rejected: number, _cancelled: number,
              _reference: string, _variant: string, _barcode: string, _avgDeliveryTime: number, _avgCheckTime: number) {
    this.received = _received;
    this.converted = _converted;
    this.rejected = _rejected;
    this.cancelled = _cancelled;
    this.reference = _reference;
    this.variant = _variant;
    this.barcode = _barcode;
    this.avgDeliveryTime = _avgDeliveryTime;
    this.avgCheckTime = _avgCheckTime;
  }

  reference: string;
  variant: string;
  barcode: string;
  received: number;
  converted: number;
  rejected: number;
  cancelled: number;
  avgDeliveryTime: number;
  avgCheckTime: number;
}

@Injectable()
export class PreOrderDataService {
  private preOrderDataObservable: ReplaySubject<[Shop[], PreOrderAnalyticsResultDto[]]> = new ReplaySubject(1);
  private lastPreOrderData: string;
  private preOrderDataByShopObservable: ReplaySubject<PreOrderAnalyticsByShopResultDto[]> = new ReplaySubject(1);

  constructor(private _shopService: ShopService, private _httpService: ByHttpService) {

  }

  public getPreOrderData(organizationId: string, from: Date, to: Date, shopId: string | null = null):
    Observable<any> {
    if (!shopId) {
      const request = {
        'from': from,
        'to': to,
      };
      const nextPreOrderData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
      if (nextPreOrderData !== this.lastPreOrderData) {
        this.lastPreOrderData = nextPreOrderData;
        zip(this._shopService.getShops(organizationId, true),
          this._httpService.doApiPostConnection<[PreOrderAnalyticsResultDto]>('/api/analytics/preorders/' +
            organizationId, request, true),
        ).subscribe((next) => {
          this.preOrderDataObservable.next(next)
        })
      }

      return this.preOrderDataObservable.pipe(
        map<[Shop[], PreOrderAnalyticsResultDto[]], PreOrderData>((result) => {
          const shops = result[0];
          const _preOrderData = result[1];
          const preOrderData = new PreOrderData();
          preOrderData.received = _preOrderData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.total)
            .reduce((a, b) => a + b, 0);
          preOrderData.converted = _preOrderData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.converted)
            .reduce((a, b) => a + b, 0);
          preOrderData.rejected = _preOrderData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.rejected)
            .reduce((a, b) => a + b, 0);
          preOrderData.cancelled = _preOrderData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.expired)
            .reduce((a, b) => a + b, 0);
          let date = from;
          while (date <= to) {
            const received = _preOrderData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.total)
              .reduce((a, b) => a + b, 0);
            const converted = _preOrderData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.converted)
              .reduce((a, b) => a + b, 0);
            const rejected = _preOrderData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.rejected)
              .reduce((a, b) => a + b, 0);
            const cancelled = _preOrderData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.expired)
              .reduce((a, b) => a + b, 0);
            preOrderData.datePreOrder.push(new PreOrderChartData(date,
              received, converted, rejected, cancelled));
            date = this.nextDate(date);
          }

          preOrderData.shops = [];
          shops.filter(shop => _preOrderData.map(data => data.id.shopId).indexOf(shop.id) !== -1).forEach(shop => {
            let avgDeliveryTime: number;
            let avgCheckTime: number;
            const received = _preOrderData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.total)
              .reduce((a, b) => a + b, 0);
            const converted = _preOrderData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.converted)
              .reduce((a, b) => a + b, 0);
            const rejected = _preOrderData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.rejected)
              .reduce((a, b) => a + b, 0);
            const cancelled = _preOrderData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.expired)
              .reduce((a, b) => a + b, 0);
            if (_preOrderData.filter(shopDate => shopDate.avgDeliveryTime > 0
              && shopDate.id.shopId === shop.id).length) {
              avgDeliveryTime = _preOrderData
                .filter(shopDate => shopDate.id.shopId === shop.id)
                .map(shopDate => shopDate.avgDeliveryTime ? shopDate.avgDeliveryTime : 0)
                .reduce((a, b) => a + b, 0) / (1000 *
                _preOrderData.filter(shopDate => shopDate.avgDeliveryTime > 0
                  && shopDate.id.shopId === shop.id).length);
            } else {
              avgDeliveryTime = 0;
            }
           if (_preOrderData.filter(shopDate => shopDate.avgCheckTime > 0
             && shopDate.id.shopId === shop.id).length > 0) {
             avgCheckTime = _preOrderData
               .filter(shopDate => shopDate.id.shopId === shop.id)
               .map(shopDate => shopDate.avgCheckTime ? shopDate.avgCheckTime : 0)
               .reduce((a, b) => a + b, 0) / (1000 *
               _preOrderData.filter(shopDate => shopDate.avgCheckTime > 0 && shopDate.id.shopId === shop.id).length);
           } else {
             avgCheckTime = 0;
           }
            preOrderData.shops.push(
              new PreOrderShopData(received, converted, rejected, cancelled, shop.name, shop.id, shop.posId,
                avgDeliveryTime, avgCheckTime));
          });
          return preOrderData;
        }),
      );
    } else {
      const request = {
        'from': from,
        'to': to,
      };
      const nextPreOrderData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to) + shopId;
      if (nextPreOrderData !== this.lastPreOrderData) {
        this.lastPreOrderData = nextPreOrderData;
        this._httpService.doApiPostConnection<[PreOrderAnalyticsByShopResultDto]>('/api/analytics/preorders/shop/' +
          shopId, request, true).subscribe((next) => {
          this.preOrderDataByShopObservable.next(next)
        })
      }

      return this.preOrderDataByShopObservable.pipe(
        map<PreOrderAnalyticsByShopResultDto[], PreOrderByShopData>((result) => {
          const _preOrderByShopData = result;
          const preOrderByShopData = new PreOrderByShopData();
          preOrderByShopData.received = _preOrderByShopData.map(shopData => shopData.total)
            .reduce((a, b) => a + b, 0);
          preOrderByShopData.converted = _preOrderByShopData.map(shopData => shopData.converted)
            .reduce((a, b) => a + b, 0);
          preOrderByShopData.rejected = _preOrderByShopData.map(shopData => shopData.rejected)
            .reduce((a, b) => a + b, 0);
          preOrderByShopData.cancelled = _preOrderByShopData.map(shopData => shopData.expired)
            .reduce((a, b) => a + b, 0);
          let date = from;
          while (date <= to) {
            const received = _preOrderByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.total)
              .reduce((a, b) => a + b, 0);
            const converted = _preOrderByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.converted)
              .reduce((a, b) => a + b, 0);
            const rejected = _preOrderByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.rejected)
              .reduce((a, b) => a + b, 0);
            const cancelled = _preOrderByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.expired)
              .reduce((a, b) => a + b, 0);
            preOrderByShopData.datePreOrder.push(new PreOrderChartData(date,
              received, converted, rejected, cancelled));
            date = this.nextDate(date);
          }
          preOrderByShopData.products = [];
          _preOrderByShopData.forEach(product => {
            preOrderByShopData.products.push(
              new PreOrderProductData(product.total, product.converted, product.rejected, product.expired,
                product.id.reference, product.id.variant, product.id.barcode,
                product.avgDeliveryTime ? product.avgDeliveryTime / 1000 : 0,
                product.avgCheckTime ? product.avgCheckTime / 1000 : 0));
          });

          const group_to_values = preOrderByShopData.products.reduce(function (obj, item) {
            obj[item.reference + ' - ' + item.variant] = obj[item.reference + ' - ' + item.variant] || [];
            obj[item.reference + ' - ' + item.variant].push(item);
            return obj;
          }, {});
          const groups = Object.keys(group_to_values).map(function (key) {
            return {reference: key, products: group_to_values[key]};
          });
          preOrderByShopData.products = [];
          const prod = new Array()
          for (let i = 0; i < groups.length; i++) {
            prod[i] = groups[i].products.reduce(function (previousValue, currentValue) {
              return {
                reference: previousValue.reference,
                variant: previousValue.variant,
                avgCheckTime: groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length > 0 ?
                  previousValue.avgCheckTime + currentValue.avgCheckTime
                / groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length : 0,
                avgDeliveryTime: groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length > 0 ?
                  previousValue.avgDeliveryTime + currentValue.avgDeliveryTime
                / groups[i].products.filter(_prod => _prod.avgCheckTime > 0).length : 0,
                received: previousValue.received + currentValue.received,
                converted: previousValue.converted + currentValue.converted,
                rejected: previousValue.rejected + currentValue.rejected,
                cancelled: previousValue.cancelled + currentValue.cancelled,
              }
            });
            preOrderByShopData.products.push(new PreOrderProductData(prod[i].received, prod[i].converted,
              prod[i].rejected, prod[i].cancelled, prod[i].reference, prod[i].variant, prod[i].barcode,
              prod[i].avgDeliveryTime, prod[i].avgCheckTime));
          }
          return preOrderByShopData;
        }),
      );
    }
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }
}
