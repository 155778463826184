import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {map} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';


export class DigitalOfflineData {
  standard: number;
  international: number;
  expressShipping: number;
  shopPickup: number;
  bestMethod: string;
  shops: Array<DigitalOfflineShopData> = [];
  dateDigitalOffline: Array <DigitalOfflineChartData> = [];
}

export class DigitalOfflineByShopData {
  standard: number;
  international: number;
  expressShipping: number;
  shopPickup: number;
  bestMethod: string;
  products: Array<DigitalOfflineProductData> = [];
  dateDigitalOffline: Array <DigitalOfflineChartData> = [];
}

export class DigitalOfflineProductData {
  constructor(_standard: number, _expressShipping: number, _shopPickup: number, _international: number,
              _reference: string, _variant: string, _barcode: string) {
    this.standard = _standard;
    this.expressShipping = _expressShipping;
    this.shopPickup = _shopPickup;
    this.international = _international;
    this.reference = _reference;
    this.variant = _variant;
    this.barcode = _barcode;
  }

  reference: string;
  variant: string;
  barcode: string;
  standard: number;
  expressShipping: number;
  shopPickup: number;
  international: number;
}

export class DigitalOfflineChartData {
  constructor(_date: Date, _standard: number, _expressShipping: number, _shopPickup: number) {
    this.date = _date;
    this.standard = _standard;
    this.expressShipping = _expressShipping;
    this.shopPickup = _shopPickup;
  }

  date: Date;
  standard: number;
  expressShipping: number;
  shopPickup: number;
}

export class DigitalOfflineShopData {

  constructor(_total: number, _standard: number, _expressShipping: number, _international: number,
              _shopPickup: number, _shopName: string, _shopId: string, _shopCode: string) {
    this.total = _total;
    this.standard = _standard;
    this.expressShipping = _expressShipping;
    this.shopPickup = _shopPickup;
    this.shopName = _shopName;
    this.shopCode = _shopCode;
    this.shopId = _shopId;
    this.international = _international;
  }

  shopId: string;
  shopName: string;
  shopCode: string;
  total: number;
  standard: number;
  expressShipping: number;
  shopPickup: number;
  international: number;
}
export class DigitalOfflineAnalyticsResultDto {
  id: DigitalOfflineAnalyticsResultIdentifierDto;
  total: number;
  pickUpStore: number;
  standard: number;
  express: number;
  international: number;
}
export class DigitalOfflineAnalyticsResultIdentifierDto {
  shopId: string;
  date: number;
}
export class DigitalOfflineAnalyticsByShopResultDto {
  id: DigitalOfflineAnalyticsByShopResultIdentifierDto;
  total: number;
  pickUpStore: number;
  standard: number;
  express: number;
  international: number;
}
export class DigitalOfflineAnalyticsByShopResultIdentifierDto {
  reference: string;
  date: number;
  variant: string;
  barcode: string;
}
@Injectable()
export class DigitalOfflineDataService {
  private digitalOfflineDataObservable: ReplaySubject<[Shop[],
    DigitalOfflineAnalyticsResultDto[]]> = new ReplaySubject(1);
  private lastDigitalOfflineData: string;
  private digitalOfflineDataByShopObservable: ReplaySubject<DigitalOfflineAnalyticsByShopResultDto[]> =
    new ReplaySubject(1);


    constructor(private _shopService: ShopService, private _httpService: ByHttpService) {

  }

  public getDigitalOfflineData(organizationId: string, from: Date, to: Date, shopId: string | null = null):
    Observable<any> {

    if (!shopId) {
      const request = {
        'from': from,
        'to': to,
      };
      const nextDigitalOfflineData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
      if (nextDigitalOfflineData !== this.lastDigitalOfflineData) {
        this.lastDigitalOfflineData = nextDigitalOfflineData;
        zip(this._shopService.getShops(organizationId, true),
          this._httpService.doApiPostConnection<[DigitalOfflineAnalyticsResultDto]>('/api/analytics/digitaloffline/' +
          organizationId, request, true),
        ).subscribe((next) => {
          this.digitalOfflineDataObservable.next(next)
        })
      }

      return this.digitalOfflineDataObservable.pipe(
        map<[Shop[], DigitalOfflineAnalyticsResultDto[]], DigitalOfflineData>((result) => {
          const shops = result[0];
          const _digitalOfflineData = result[1];

          const digitalOfflineData = new DigitalOfflineData();
          digitalOfflineData.bestMethod = '';
          digitalOfflineData.standard = _digitalOfflineData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.standard)
            .reduce((a, b) => a + b, 0);
          digitalOfflineData.expressShipping = _digitalOfflineData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.express)
            .reduce((a, b) => a + b, 0);
          digitalOfflineData.shopPickup = _digitalOfflineData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.pickUpStore)
            .reduce((a, b) => a + b, 0);
          digitalOfflineData.international = _digitalOfflineData.filter(shopDateData =>
            shopId ? shopDateData.id.shopId === shopId : true)
            .map(shopDateData => shopDateData.international)
            .reduce((a, b) => a + b, 0);
          const max = [digitalOfflineData.standard, digitalOfflineData.expressShipping
            , digitalOfflineData.shopPickup].reduce((highest, current) => highest > current ? highest : current)
          if (digitalOfflineData.standard === max) {
            digitalOfflineData.bestMethod = digitalOfflineData.bestMethod.concat('Envío')
          }
          if (digitalOfflineData.expressShipping === max) {
            if (digitalOfflineData.bestMethod.length > 0 ) {
              digitalOfflineData.bestMethod = digitalOfflineData.bestMethod.concat(' - Envío Express')
            } else {
              digitalOfflineData.bestMethod = digitalOfflineData.bestMethod.concat('Envío Express')
            }
          }
          if (digitalOfflineData.shopPickup === max) {
            if (digitalOfflineData.bestMethod.length > 0 ) {
              digitalOfflineData.bestMethod = digitalOfflineData.bestMethod.concat(' - Recogida en tienda')
            } else {
              digitalOfflineData.bestMethod = digitalOfflineData.bestMethod.concat('Recogida en tienda')
            }
          }
          let date = from;
          while (date <= to) {
            const standard = _digitalOfflineData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.standard)
              .reduce((a, b) => a + b, 0);
            const expressShipping = _digitalOfflineData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.express)
              .reduce((a, b) => a + b, 0);
            const shopPickup = _digitalOfflineData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.pickUpStore)
              .reduce((a, b) => a + b, 0);
            const international = _digitalOfflineData.filter(shopDateData =>
              this.formatDate(new Date(shopDateData.id.date)) === this.formatDate(date))
              .map(shopDateData => shopDateData.international)
              .reduce((a, b) => a + b, 0);
            digitalOfflineData.dateDigitalOffline
              .push(new DigitalOfflineChartData(date, standard, expressShipping, shopPickup));
            date = this.nextDate(date);
          }

          digitalOfflineData.shops = [];
          shops.filter(shop => _digitalOfflineData.map(data => data.id.shopId).indexOf(shop.id) !== -1)
            .forEach(shop => {
            const total = _digitalOfflineData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.total)
              .reduce((a, b) => a + b, 0);
            const standard = _digitalOfflineData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.standard)
              .reduce((a, b) => a + b, 0);
            const expressShipping = _digitalOfflineData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.express)
              .reduce((a, b) => a + b, 0);
            const shopPickup = _digitalOfflineData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.pickUpStore)
              .reduce((a, b) => a + b, 0);
            const international = _digitalOfflineData
              .filter(shopDate => shopDate.id.shopId === shop.id)
              .map(shopDate => shopDate.international)
              .reduce((a, b) => a + b, 0);
            digitalOfflineData.shops.push(
              new DigitalOfflineShopData(total, standard, expressShipping, international,
                shopPickup, shop.name, shop.id, shop.posId));
          });
          return digitalOfflineData;
        }),
      );
    } else {
      const request = {
        'from': from,
        'to': to,
      };
      const nextDigitalOfflineData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to) + shopId;
      if (nextDigitalOfflineData !== this.lastDigitalOfflineData) {
        this.lastDigitalOfflineData = nextDigitalOfflineData;
        this._httpService.doApiPostConnection<[DigitalOfflineAnalyticsByShopResultDto]>(
          '/api/analytics/digitaloffline/shop/' + shopId, request, true).subscribe((next) => {
          this.digitalOfflineDataByShopObservable.next(next)
        })
      }

      return this.digitalOfflineDataByShopObservable.pipe(
        map<DigitalOfflineAnalyticsByShopResultDto[], DigitalOfflineByShopData>((result) => {
          const _digitalOfflineByShopData = result;
          const digitalOfflineByShopData = new DigitalOfflineByShopData();
          digitalOfflineByShopData.bestMethod = '';
          digitalOfflineByShopData.standard = _digitalOfflineByShopData.map(shopData => shopData.standard)
            .reduce((a, b) => a + b, 0);
          digitalOfflineByShopData.expressShipping = _digitalOfflineByShopData.map(shopData => shopData.express)
            .reduce((a, b) => a + b, 0);
          digitalOfflineByShopData.shopPickup = _digitalOfflineByShopData.map(shopData => shopData.pickUpStore)
            .reduce((a, b) => a + b, 0);
          digitalOfflineByShopData.international = _digitalOfflineByShopData.map(shopData => shopData.international)
            .reduce((a, b) => a + b, 0);
          const max = [digitalOfflineByShopData.standard, digitalOfflineByShopData.expressShipping
            , digitalOfflineByShopData.shopPickup].reduce((highest, current) => highest > current ? highest : current)
          if (digitalOfflineByShopData.standard === max) {
            digitalOfflineByShopData.bestMethod = digitalOfflineByShopData.bestMethod.concat('Envío')
          }
          if (digitalOfflineByShopData.expressShipping === max) {
            if (digitalOfflineByShopData.bestMethod.length > 0 ) {
              digitalOfflineByShopData.bestMethod = digitalOfflineByShopData.bestMethod.concat(' - Envío Express')
            } else {
              digitalOfflineByShopData.bestMethod = digitalOfflineByShopData.bestMethod.concat('Envío Express')
            }
          }
          if (digitalOfflineByShopData.shopPickup === max) {
            if (digitalOfflineByShopData.bestMethod.length > 0 ) {
              digitalOfflineByShopData.bestMethod = digitalOfflineByShopData.bestMethod.concat(' - Recogida en tienda')
            } else {
              digitalOfflineByShopData.bestMethod = digitalOfflineByShopData.bestMethod.concat('Recogida en tienda')
            }
          }
          let date = from;
          while (date <= to) {
            const standard = _digitalOfflineByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.standard)
              .reduce((a, b) => a + b, 0);
            const expressShipping = _digitalOfflineByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.express)
              .reduce((a, b) => a + b, 0);
            const shopPickup = _digitalOfflineByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.pickUpStore)
              .reduce((a, b) => a + b, 0);
            const international = _digitalOfflineByShopData.filter(shopData =>
              this.formatDate(new Date(shopData.id.date)) === this.formatDate(date))
              .map(shopData => shopData.international)
              .reduce((a, b) => a + b, 0);
            digitalOfflineByShopData.dateDigitalOffline
              .push(new DigitalOfflineChartData(date, standard, expressShipping, shopPickup));
            date = this.nextDate(date);
          }
          digitalOfflineByShopData.products = [];
          _digitalOfflineByShopData.forEach(product => {
            digitalOfflineByShopData.products.push(
              new DigitalOfflineProductData(product.standard, product.express, product.pickUpStore,
                product.international, product.id.reference, product.id.variant, product.id.barcode));
          });

          const group_to_values = digitalOfflineByShopData.products.reduce(function (obj, item) {
            obj[item.reference + ' - ' + item.variant] = obj[item.reference + ' - ' + item.variant] || [];
            obj[item.reference + ' - ' + item.variant].push(item);
            return obj;
          }, {});

          const groups = Object.keys(group_to_values).map(function (key) {
            return {reference: key, products: group_to_values[key]};
          });
          digitalOfflineByShopData.products = [];
          const prod = new Array()
          for (let i = 0; i < groups.length; i++) {
            prod[i] = groups[i].products.reduce(function (previousValue, currentValue) {
              return {
                reference: previousValue.reference,
                variant: previousValue.variant,
                standard: previousValue.standard  + currentValue.standard,
                international: previousValue.international  + currentValue.international,
                expressShipping: previousValue.expressShipping  + currentValue.expressShipping,
                shopPickup: previousValue.shopPickup + currentValue.shopPickup,
              }
            });
            digitalOfflineByShopData.products.push(new DigitalOfflineProductData(prod[i].standard,
              prod[i].expressShipping, prod[i].shopPickup, prod[i].international, prod[i].reference, prod[i].variant,
              prod[i].barcode));
          }
          return digitalOfflineByShopData;
        }),
      );
    }
  }


  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }
  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }
}
