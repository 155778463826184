import {Inject, Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {FirebaseApp} from 'angularfire2';
import * as firebase from 'firebase';
import {OAuthService} from 'angular-oauth2-oidc';
import {User} from '../pages/login';
import App = firebase.app.App;
import Messaging = firebase.messaging.Messaging;
import {Observable} from 'rxjs';


class SellsFromShop {

  sells: Array<any> = [];

  nextPage: number = 0;

  requestingSells: boolean = false;

  lastPageReturned: boolean = false;

  constructor(public shopId: String) {
  }

}

@Injectable()
export class SellService {

  public sellsByShop: { [key: string]: SellsFromShop } = {};

  private sellsSummaryByOrganization: { [key: string]: Array<any> } = {};

  public sellsUnread: Array<any> = [];

  private _messaging: Messaging;

  private notificationsEnabled: boolean = false;

  constructor(private _httpService: ByHttpService,
              @Inject(FirebaseApp) private _firebaseApp: App, private _oauthService: OAuthService) {
    const user = _oauthService.getIdentityClaims() as User;
    if (user && user.roles && user.roles.indexOf('ROLE_ADMIN_ORGANIZATION') >= 0) {
      if ((<any>window).Worker && (<any>window).Notification) {
        this._messaging = firebase.messaging(this._firebaseApp);
        this._messaging.requestPermission()
          .then(() => {
            this.notificationsEnabled = true;
            this.getToken();
          })
          .catch((error) => {
          });
        this.refreshToken();
      }
    }
  }


  private getToken(): void {
    this._messaging.getToken()
      .then((currentToken) => {
        if (currentToken) {
          this._httpService.doApiPutConnection('/api/organization/appNotification?registrationId=' + currentToken)
            .subscribe(() => {
              console.log('Register app notification in back');
              this._messaging.onMessage((payload) => {
                this.onNewMessage(payload);
              });
              (<any>navigator).serviceWorker.addEventListener('message', (payload) => {
                this.onNewMessage(payload);
              });
            }, (err) => {
              console.log('Error registering app notifications in back: ' + err);
            });
        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      })
      .catch(function (err) {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  private refreshToken(): void {
    this._messaging.onTokenRefresh(function () {
      this.getToken();
    });
  }

  public onNewMessage(payload): void {
    if (!payload.data.id) {
      return;
    }
    console.log('onNewMessage: ', payload);
    if (typeof payload.data.orderItem === 'string') {
      payload.data.orderItem = JSON.parse(payload.data.orderItem);
    }
    this.sellsUnread.push(payload.data);
    if (this.sellsByShop[payload.data.shopId]) {
      this.sellsByShop[payload.data.shopId].sells.unshift(payload.data);
    }
    if (this.sellsByShop['-1']) {
      this.sellsByShop['-1'].sells.unshift(payload.data);
    }
  }

  loadSellSummary(organizationId: string): Observable<Array<any>> {
    console.log('sell summary of organizationId: ' + organizationId);
    if (this.sellsSummaryByOrganization && this.sellsSummaryByOrganization[organizationId]) {
      console.log('Return cache sellSummary data: ' + this.sellsSummaryByOrganization[organizationId].length);
      return Observable.of(this.sellsSummaryByOrganization[organizationId]);
    } else {
      return this._httpService.doApiGetConnection<Array<any>>('/api/organization/' + organizationId
        + '/sells/yearSummary')
        .map((doc) => {
          this.sellsSummaryByOrganization[organizationId] = doc;
          return doc;
        });
    }
  }

  getSells(organizationId: string, shopId: string, nextPage: boolean): Observable<any> {
    const shopKey = organizationId + '-' + shopId;
    console.log('Get sells from shop ' + shopId);
    if (!this.sellsByShop[shopKey]) {
      this.sellsByShop[shopKey] = new SellsFromShop(shopKey);
    }
    if (this.sellsByShop[shopKey].sells && this.sellsByShop[shopKey].sells.length > 0 && !nextPage) {
      return Observable.of(this.sellsByShop[shopKey].sells);
    } else {
      if (this.sellsByShop[shopKey].lastPageReturned) {
        console.log('Last page reached...');
        return Observable.of(this.sellsByShop[shopKey].sells);
      }
      if (this.sellsByShop[shopKey].requestingSells) {
        console.log('Already requesting data...');
        return Observable.of(this.sellsByShop[shopKey].sells);
      }
      let url: string = '/api/organization/' + organizationId + '/sells/';
      if (shopId !== '-1') {
        url += shopId;
      }
      url += '?page=' + this.sellsByShop[shopKey].nextPage;
      return this._httpService.doApiGetConnection(url)
        .map((doc) => {
          this.sellsByShop[shopKey].nextPage++;
          this.sellsByShop[shopKey].requestingSells = false;
          if (doc instanceof Array) {
            doc.forEach((sell) => {
              this.sellsByShop[shopKey].sells.push(sell);
            });
            if (doc.length === 0) {
              console.log('Last page reached');
              this.sellsByShop[shopKey].lastPageReturned = true;
            }
          }
          return this.sellsByShop[shopKey].sells;
        });
    }
  }

  resetUnreadSells() {
    this.sellsUnread.length = 0;
  }
}
