import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';

import 'style-loader!./login.scss';
import {BaThemeSpinner} from '../../theme/services/baThemeSpinner';
import {Organization} from '../../services/organization.service';
import {Shop} from '../../services/shops.service';
import {ApiConfig} from '../../app-config';
import {APP_CONFIG} from '../app.config';
import {GlobalState} from '../../global.state';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.html',
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

  form: FormGroup;
  email: AbstractControl;
  password: AbstractControl;
  submitted: boolean = false;
  oauthService: OAuthService;
  router: Router;
  error: boolean = false;
  errorMessage: string = '';
  recoverPasswordUrl: string = '';
  showAcceptConditions: boolean = false;
  enableUser: string = null;

  constructor(fb: FormBuilder, private _oauthService: OAuthService, private _router: Router,
              @Inject(APP_CONFIG) private config: ApiConfig, private _spinner: BaThemeSpinner,
              private _state: GlobalState) {
    this.form = fb.group({
      'email': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      'password': ['', Validators.compose([Validators.required, Validators.minLength(4)])],
    });

    this.email = this.form.controls['email'];
    this.password = this.form.controls['password'];
    this.oauthService = _oauthService;

    this.router = _router;

    this.recoverPasswordUrl = config.getBaseUrl() + '/changePassword';
  }


  ngOnInit(): void {
    this._spinner.hide();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._state.notifyDataChanged('fullScreen', true);
    })
  }

  ngOnDestroy(): void {
    this._state.notifyDataChanged('fullScreen', false);
  }

  public onSubmit(event): void {
    if (event) {
      event.preventDefault();
    }
    this.submitted = true;
    if (this.form.valid) {
      console.log('Fetching token using user: ' + this.email.value + ', password: ' + this.password.value);
      this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.email.value, this.password.value)
        .then((resp) => {
          // Using the loaded user data
          const claims = this.oauthService.getIdentityClaims() as User;
          if (claims) console.log('given_name', claims.name);

          this.router.navigate(['pages']);

        }).catch((err: HttpErrorResponse) => {
        this.error = true;
        if (err.error && err.error.error_description) {
          const errorDescription = err.error.error_description;
          if (errorDescription && errorDescription === 'User is disabled') {
            this.enableUser = this.email.value;
            this.showAcceptConditions = true;
          } else {
            this.errorMessage = err.error.error_description;
          }
        }
      });
    }
  }

  public onUserEnabled(userEnabled: boolean) {
    if (userEnabled) {
      this.onSubmit(null);
    }
  }

}

export class User {
  id: string;
  username: string;
  email: string;
  gender: string;
  name: string;
  roles: Array<string>;
  organization: Organization;
  organizationId: string;
  enabled: boolean;
  shop: Shop;
  password: string;
  confirmPassword: string;

  constructor(user: User = null) {
    if (user) {
      this.id = user.id;
      this.username = user.username;
      this.email = user.email;
      this.gender = user.gender;
      this.name = user.name;
      this.roles = user.roles;
      this.organization = new Organization(user.organization);
      this.organizationId = user.organizationId;
      this.enabled = user.enabled;
      this.shop = new Shop(user.shop);
      this.password = user.password;
      this.confirmPassword = user.confirmPassword
    }
  }

}
