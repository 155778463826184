import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {ReplaySubject} from 'rxjs/ReplaySubject';

export class RangeDate {
  fromDate: Date;
  toDate: Date;

  constructor(_fromDate: Date, _toDate: Date) {
    this.fromDate = _fromDate;
    this.toDate = _toDate;
  }

}

export class SubPageList {
  list: Array<SubPage>;
  exists: boolean;

  constructor() {
    this.list = [];
    this.exists = false;
  }
}

export class SubPage {
  subPageLabel: String;
  subPageRouterLink: String;
  hasLink: boolean;

  constructor(label: String, routerLink: String, _hasLink: boolean) {
    this.subPageLabel = label;
    this.subPageRouterLink = routerLink;
    this.hasLink = _hasLink;
  }
}

@Injectable()
export class HeaderDateService {
  private rangeDate = new ReplaySubject<RangeDate>(1);

  private subPageList = new Subject<SubPageList>();

  subPageListChanged$ = this.subPageList.asObservable();

  getRangeDate(): Subject<RangeDate> {
    return this.rangeDate;
  }

  newDate(_rangeDate: RangeDate) {
    this.setRangeDate(_rangeDate);
  }

  initDates(section: string) {
      const previousDate: Date = new Date();
      const currentDate: Date = new Date();
      previousDate.setMonth(new Date().getMonth() - 1);
      previousDate.setDate(new Date().getDate());
      this.setRangeDate(new RangeDate(previousDate, currentDate));
  }

  setSubPageList(_subPageList: SubPageList) {
    this.subPageList.next(_subPageList);
  }

  private setRangeDate(_rangeDate: RangeDate) {
    console.log('New range date', _rangeDate);
    this.rangeDate.next(_rangeDate);
  }

}
