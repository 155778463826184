import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {User} from '../pages/login';
import {OAuthService} from 'angular-oauth2-oidc';
import {Organization, OrganizationService} from './organization.service';
import {Subject, Observable} from 'rxjs';

export class Address {
  zipCode: string;
  country: string;
  city: string;
  street: string;

  constructor(address: Address = null) {
    if (address) {
      this.zipCode = address.zipCode;
      this.country = address.country;
      this.city = address.city;
      this.street = address.street;
    }
  }

  get countryName(): String {
    switch  (this.country){
      case 'ES':
        return 'España'
      
      case 'PT':
        return 'Portugal'
      
      case 'FR':
        return 'Francia'
      
      default:
        return this.country;
      
    }
  }

}

export class ShopNoWinstore{
  name: string;
  phone: string;
  posId: string;
  lastSyncBulkStockageDate: Date;
  address: Address = new Address();
  constructor(shop: ShopNoWinstore = null) {
    if (shop) {
      this.name = shop.name;
      this.phone = shop.phone;
      this.posId = shop.posId;
      this.lastSyncBulkStockageDate = shop.lastSyncBulkStockageDate;
      if (shop.address) {
        this.address = new Address(shop.address);
      }
    }
  }
}

export class ShopLocation {
  longitude: number;
  latitude: number;

  constructor(longitude: number = null, latitude: number = null) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

export class Shop {
  id: string;
  name: string;
  phone: string;
  organization: Organization = new Organization();
  posId: string;
  allowOnlineOrders: boolean;
  address: Address = new Address();
  location: ShopLocation = new ShopLocation();

  constructor(shop: Shop = null) {
    if (shop) {
      this.id = shop.id;
      this.name = shop.name;
      this.phone = shop.phone;
      this.posId = shop.posId;
      this.allowOnlineOrders = shop.allowOnlineOrders;
      if (shop.organization) {
        this.organization = new Organization(shop.organization);
      }
      this.organization = shop.organization;
      if (shop.address) {
        this.address = new Address(shop.address);
      }
      this.location = shop.location;
    }
  }

}

@Injectable()
export class ShopService {

  public onEditedShop: Subject<Shop>;

  shopsByOrganization: { [key: string]: Array<Shop> } = {};
  allShopsByOrganization: { [key: string]: Array<Shop> } = {};
  shopsNoWinstore: { [key: string]: Array<ShopNoWinstore> } = {};

  constructor(private _httpService: ByHttpService, private _oAuthService: OAuthService,
              private _organizationService: OrganizationService) {
    this.onEditedShop = new Subject<Shop>();
  }

  getShops(organizationId: string, includeAllShops: boolean | null = false): Observable<Array<Shop>> {
    return Observable.create((subscriber) => {
      if (this.shopsByOrganization[organizationId] && includeAllShops !== true) {
        subscriber.next(this.shopsByOrganization[organizationId]);
      } else if (this.allShopsByOrganization[organizationId] && includeAllShops === true) {
        subscriber.next(this.allShopsByOrganization[organizationId]);
      } else {
        let url;
        if (includeAllShops === true) {
          url = '/api/organization/' + organizationId + '/allshops'
        } else {
          url = '/api/organization/' + organizationId + '/shops'
        }
        this._httpService.doApiGetConnection<Array<Shop>>(url)
          .map(shops => shops.map(shop => new Shop(shop)))
          .subscribe((doc) => {
            if (includeAllShops === true) {
              this.allShopsByOrganization[organizationId] = doc;
            } else {
              this.shopsByOrganization[organizationId] = doc;
            }
            subscriber.next(doc);
          }, (doc) => {
            subscriber.error(doc);
          });
      }
    });
  }

  saveShop(organizationId: string, shop: Shop): Observable<Shop> {
    return Observable.create((subscriber) => {
      const _shop = jQuery.extend(true, {}, shop);
      this._httpService.doApiPutConnection<Shop>('/api/organization/' + organizationId + '/shops/' + _shop.id, _shop)
        .map(newShop => new Shop(newShop))
        .subscribe((savedShop: Shop) => {
          for (let i = 0; i < this.shopsByOrganization[organizationId].length; i++) {
            if (savedShop.id === this.shopsByOrganization[organizationId][i].id) {
              this.shopsByOrganization[organizationId][i] = savedShop;
            }
          }
          this.onEditedShop.next(savedShop);
          subscriber.next(savedShop);
        }, (err) => {
          subscriber.error(err);
        });
    });
  }

  newShop(organizationId: string, shop: Shop): Observable<Shop> {
    return Observable.create((subscriber) => {
      this._httpService.doApiPostConnection<Shop>('/api/organization/' + organizationId + '/shops', shop)
        .map(newShop => new Shop(newShop))
        .subscribe((newShop: Shop) => {
          console.log('Adding new shop', newShop);
          if (!this.shopsByOrganization[organizationId]) {
            this.shopsByOrganization[organizationId] = [];
          }
          this.shopsByOrganization[organizationId].push(newShop);
          this.refreshOrganization(organizationId);
          subscriber.next(newShop);
        }, (err) => {
          subscriber.error(err);
        });
    });
  }

  removeShop(organizationId: string, shop: Shop): Observable<Shop> {
    return Observable.create((subscriber) => {
      this._httpService.doApiDeleteConnection('/api/organization/' + organizationId + '/shops/' + shop.id)
        .subscribe((doc) => {
          let i = this.shopsByOrganization[organizationId].length;
          while (i--) {
            if (this.shopsByOrganization[organizationId][i].id === shop.id) {
              console.log('Removed shop: ' + shop.id);
              this.shopsByOrganization[organizationId].splice(i, 1);
            }
          }
          this.refreshOrganization(organizationId);
          subscriber.next(doc);
        }, (err) => {
          subscriber.error(err);
        });
    });
  }

  private refreshOrganization(organizationId: string) {
    const user = this._oAuthService.getIdentityClaims() as User;
    if (!user || !user.roles || user.roles.indexOf('ROLE_ADMIN') < 0) {
      this._organizationService.getOrganizations(organizationId, true)
        .subscribe((organization) => {
          this._organizationService.setOrganization(organization[0]);
        });
    }
  }

  getNoIntegradorWSTShop (organizationId: string) : Observable<Array<ShopNoWinstore>>{
    return Observable.create((subscriber) => {
      
        let url;
        url = '/api/stockage/' + organizationId + '/notactiveshops'
        this._httpService.doApiGetConnection<Array<ShopNoWinstore>>(url)
          .map(shops => shops.map(shop => new ShopNoWinstore(shop)))
          .subscribe((doc) => {
            this.shopsNoWinstore[organizationId] = doc;
            subscriber.next(doc);
          }, (doc) => {
          subscriber.error(doc);
        });
      
    });
  }
}
