import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import 'style-loader!./pagination.scss';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.html',
})
export class PaginationComponent implements OnDestroy, OnInit, OnChanges {

  @Input('numberOfPages') numberOfPages: number;
  @Input('goToFirstPage') goToFirstPage: boolean;
  @Output() pageNum = new EventEmitter<number>();
  private currentPage: number;
  private dummyList;
  private Math;
  constructor() {
    this.Math = Math;
  }

  ngOnInit() {
    this.currentPage = 1;
    this.pageNum.emit(this.currentPage);
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numberOfPages !== undefined && changes.numberOfPages.currentValue !== undefined) {
      this.dummyList = Array(this.numberOfPages);
    }
    if (changes.goToFirstPage !== undefined && changes.goToFirstPage.currentValue !== undefined) {
      this.currentPage = 1;
    }
  }

  changePage(newPageNum: number) {
    if (newPageNum > 0 && newPageNum <= this.numberOfPages) {
      this.currentPage = newPageNum;
      this.pageNum.emit(this.currentPage);
      this.dummyList = Array(this.numberOfPages);
    }
  }
}
