import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {Observable, zip} from 'rxjs';
import {Shop, ShopService} from './shops.service';
import {map} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/ReplaySubject';

export class AppBenefitsDto {
  completedSells: Array<CompletedSellDto>;
  rejectedSells: Array<CompletedSellDto>;
}

export class CompletedSellDto {
  id: CompletedSellDtoIdentifier;
  total: number;
  price: number;
  channel: string;
}

export class CompletedSellDtoIdentifier {
  date: number;
  shopId: string;
}

export class AppBenefitsChartData {
  constructor(_date: Date, _priceCompleted: number, _priceRejected: number) {
    this.date = _date;
    this.priceCompleted = _priceCompleted;
    this.priceRejected = _priceRejected;
  }
  date: Date;
  priceCompleted: number;
  priceRejected: number;
}

export class AppBenefitsData {
  priceCompleted: number;
  priceRejected: number;
  channels: Array<AppBenefitsChannelData> = [];
  dateAppBenefits: Array<AppBenefitsChartData> = [];
}

export class AppBenefitsChannelData {
  priceCompleted: number;
  priceRejected: number;
  channelName: string;
  constructor () {
    this.priceRejected = 0;
    this.priceCompleted = 0;
  }
}

@Injectable()
export class AppBenefitsDataService {
  private appBenefitsDataObservable: ReplaySubject<AppBenefitsDto> = new ReplaySubject(1);
  private lastAppBenefitsData: string;
  constructor(private _httpService: ByHttpService) {
  }

  public getAppBenefitsData(organizationId: string, from: Date, to: Date): Observable<AppBenefitsData> {
    const request = {
      'from': from,
      'to': to,
    };
    const nextAppBenefitsData = organizationId + '-' + this.formatDate(from) + '-' + this.formatDate(to);
    if (nextAppBenefitsData !== this.lastAppBenefitsData) {
      this.lastAppBenefitsData = nextAppBenefitsData;
        this._httpService.doApiPostConnection<AppBenefitsDto>('/api/analytics/appbenefits/'
          + organizationId, request, true).subscribe((next) => {
        this.appBenefitsDataObservable.next(next)
      })
    }

    return this.appBenefitsDataObservable.pipe(
      map<AppBenefitsDto, AppBenefitsData>((result) => {
        const _appBenefitsData = result;
        if (_appBenefitsData.rejectedSells === undefined) _appBenefitsData.rejectedSells = [];
        if (_appBenefitsData.completedSells === undefined) _appBenefitsData.completedSells = [];
        const appBenefitsData = new AppBenefitsData();
        appBenefitsData.priceRejected = Number(_appBenefitsData.rejectedSells.map(appBenefitsRejected =>
          appBenefitsRejected.price).reduce((a, b) => a + b, 0).toFixed(2));
        appBenefitsData.priceCompleted = Number(_appBenefitsData.completedSells.map(appBenefitsCompleted =>
          appBenefitsCompleted.price).reduce((a, b) => a + b, 0).toFixed(2));
        const preOrderChannelData  = new AppBenefitsChannelData();
        const clickCollectChannelData  = new AppBenefitsChannelData();
        const digitalOfflineChannelData  = new AppBenefitsChannelData();
        const sFSChannelData  = new AppBenefitsChannelData();
        preOrderChannelData.channelName = 'PreOrder';
        preOrderChannelData.priceRejected =  Number(_appBenefitsData.rejectedSells.filter(appBenefitsRejected =>
          appBenefitsRejected.channel === 'PreOrder').map( it => it.price).reduce((a, b) => a + b, 0).toFixed(2));
        preOrderChannelData.priceCompleted =  Number(_appBenefitsData.completedSells.filter(appBenefitsCompleted =>
          appBenefitsCompleted.channel === 'PreOrder').map( it => it.price).reduce((a, b) => a + b, 0).toFixed(2));
        appBenefitsData.channels.push(preOrderChannelData);
        clickCollectChannelData.channelName = 'Click & Collect';
        clickCollectChannelData.priceRejected =  Number(_appBenefitsData.rejectedSells.filter(appBenefitsRejected =>
          appBenefitsRejected.channel === 'Click & Collect').map( it => it.price)
          .reduce((a, b) => a + b, 0).toFixed(2));
        clickCollectChannelData.priceCompleted =  Number(_appBenefitsData.completedSells.filter(appBenefitsCompleted =>
          appBenefitsCompleted.channel === 'Click & Collect').map( it => it.price)
          .reduce((a, b) => a + b, 0).toFixed(2));
        appBenefitsData.channels.push(clickCollectChannelData);
        digitalOfflineChannelData.channelName = 'Digital Offline';
        digitalOfflineChannelData.priceRejected =  Number(_appBenefitsData.rejectedSells.filter(appBenefitsRejected =>
          appBenefitsRejected.channel === 'Digital Offline').map( it => it.price)
          .reduce((a, b) => a + b, 0).toFixed(2));
        digitalOfflineChannelData.priceCompleted =  Number(_appBenefitsData.completedSells
          .filter(appBenefitsCompleted =>
          appBenefitsCompleted.channel === 'Digital Offline').map( it => it.price)
          .reduce((a, b) => a + b, 0).toFixed(2));
        appBenefitsData.channels.push(digitalOfflineChannelData);
        sFSChannelData.channelName = 'Ship From Store';
        sFSChannelData.priceRejected =  Number(_appBenefitsData.rejectedSells.filter(appBenefitsRejected =>
          appBenefitsRejected.channel === 'Ship From Store').map( it => it.price)
          .reduce((a, b) => a + b, 0).toFixed(2));
        sFSChannelData.priceCompleted =  Number(_appBenefitsData.completedSells.filter(appBenefitsCompleted =>
          appBenefitsCompleted.channel === 'Ship From Store').map( it => it.price)
          .reduce((a, b) => a + b, 0).toFixed(2));
        appBenefitsData.channels.push(sFSChannelData);

        let date = from;
        while (date <= to) {
          const completed =  Number(_appBenefitsData.completedSells.filter(completedSell =>
            this.formatDate(new Date(completedSell.id.date)) === this.formatDate(date))
            .map(completedSell => completedSell.price)
            .reduce((a, b) => a + b, 0).toFixed(2));
          const rejected =  Number(_appBenefitsData.rejectedSells.filter(rejectedSell =>
            this.formatDate(new Date(rejectedSell.id.date)) === this.formatDate(date))
            .map(rejectedSell => rejectedSell.price)
            .reduce((a, b) => a + b, 0).toFixed(2));
          appBenefitsData.dateAppBenefits.push(new AppBenefitsChartData(date,
            completed, rejected));
          date = this.nextDate(date);
        }
        return appBenefitsData;
      }),
    );
  }

  private formatDate(d: Date): string {
    return '' + d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2);
  }

  private nextDate(fromDate: Date): Date {
    const nextDate = new Date(fromDate);
    nextDate.setDate(fromDate.getDate() + 1);

    return nextDate;
  }
}
