import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {BaThemeConfig} from './theme.config';
import {BaThemeConfigProvider} from './theme.configProvider';
import {BaCardBlurDirective} from './components/baCard/baCardBlur.directive';
import {BaScrollPositionDirective, BaThemeRunDirective} from './directives';
import {BaAppPicturePipe, BaKameleonPicturePipe, BaProfilePicturePipe, TruncatePipe} from './pipes';
import {BaImageLoaderService, BaMenuService, BaThemePreloader, BaThemeSpinner} from './services';

import {EmailValidator, EqualPasswordsValidator} from './validators';
import {BaSlimScrollDirective} from './directives/baSlimScroll';
import {LocalizationModule} from 'angular-l10n';
import {AgmCoreModule} from '@agm/core';
import {MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule} from '@angular/material';
import {BsDatepickerModule, BsDropdownModule, DatepickerModule} from 'ngx-bootstrap';

import {
  NgxChartComponent,
  BaBackTopComponent,
  BaCardComponent,
  BaChartistChartComponent,
  BaCheckboxComponent,
  BaContentTopComponent,
  BaMenuComponent,
  BaMenuItemComponent,
  BaMsgCenterComponent,
  BaMultiCheckboxComponent,
  BaPageTopComponent,
  BaSidebarComponent,
  LastSyncsComponent,
  ResultImportComponent,
  GoogleMapsComponent,
  HeaderDatesComponent,
  GraphUsageComponent,
  GraphDigitalOfflineComponent,
  GraphReservationsComponent,
  GraphClickCollectComponent,
  GraphShipFromStoreComponent,
  GraphShipFromStoreByShopComponent,
  GraphStockComponent,
  PaginationComponent,
} from './components';


const NGA_COMPONENTS = [
  NgxChartComponent,
  BaBackTopComponent,
  BaCardComponent,
  BaChartistChartComponent,
  BaCheckboxComponent,
  BaContentTopComponent,
  BaMenuItemComponent,
  BaMenuComponent,
  BaMsgCenterComponent,
  BaMultiCheckboxComponent,
  BaPageTopComponent,
  BaSidebarComponent,
  ResultImportComponent,
  LastSyncsComponent,
  GoogleMapsComponent,
  HeaderDatesComponent,
  GraphUsageComponent,
  GraphDigitalOfflineComponent,
  GraphReservationsComponent,
  GraphClickCollectComponent,
  GraphShipFromStoreComponent,
  GraphShipFromStoreByShopComponent,
  GraphStockComponent,
  PaginationComponent,
];

const NGA_DIRECTIVES = [
  BaScrollPositionDirective,
  BaSlimScrollDirective,
  BaThemeRunDirective,
  BaCardBlurDirective,
];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  TruncatePipe,
];

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  BaThemeSpinner,
  BaMenuService,
];

const NGA_VALIDATORS = [
  EmailValidator,
  EqualPasswordsValidator,
];

@NgModule({
  declarations: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LocalizationModule,
    AgmCoreModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    DatepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
],
  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
  ],
})
export class NgaModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders> {
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        ...NGA_SERVICES,
      ],
    };
  }
}
