import {Injectable} from '@angular/core';
import {ByHttpService} from './http.services';
import {User} from '../pages/login';
import {OrganizationService} from './organization.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {Shop, ShopService} from './shops.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';


@Injectable()
export class UserService {

  usersByOrganization: { [key: string]: Array<User> } = {};

  private _roles: Array<any> = [{'name': 'Admin. Global', 'value': 'ROLE_ADMIN'},
    {'name': 'Administrador', 'value': 'ROLE_ADMIN_ORGANIZATION'},
    {'name': 'Dependiente', 'value': 'ROLE_SELLER'}];

  constructor(private _httpService: ByHttpService, private _oAuthService: OAuthService,
              private _organizationService: OrganizationService, private _shopService: ShopService) {
    this._shopService.onEditedShop.subscribe((shop) => {
      this.onEditShop(shop);
    });
  }

  get roles(): Array<any> {
    return this._roles;
  }

  getUsers(organizationId: string, onlyAssistants: boolean = false): Observable<Array<User>> {
    return new Observable<Array<User>>((subscriber) => {
      console.log('get users from organization: ' + organizationId);
      if (this.usersByOrganization[organizationId] && this.usersByOrganization[organizationId].length > 0) {
        console.log('Resolving users from cache', this.usersByOrganization[organizationId]);
        subscriber.next(this.usersByOrganization[organizationId]);
      } else {
        this._httpService.doApiGetConnection<Array<User>>('/api/organization/' + organizationId +
          '/users?onlyAssistants=' + onlyAssistants)
          .map(users => users.map(user => new User(user)))
          .subscribe((doc) => {
            this.usersByOrganization[organizationId] = doc;
            subscriber.next(this.usersByOrganization[organizationId]);
          }, (doc) => {
            subscriber.error(doc);
          });
      }
    });
  }

  updatePassword(userId: string, organizationId: string, password: string, confirmPassword: string): Observable<User> {
    const request = {
      'userId': userId,
      'password': password,
      'confirmPassword': confirmPassword,
    };
    return this._httpService.doApiPostConnection<User>('/api/organization/' + organizationId +
      '/users/updatePassword', request);
  }

  getRoleValue(user: User): string {
    if (user && user.roles) {
      for (let i = 0; i < this._roles.length; i++) {
        if (user.roles.indexOf(this._roles[i].value) >= 0) {
          return this._roles[i].value;
        }
      }
    }
    return 'Desconocido';
  }

  getRoleName(user: User) {
    if (user && user.roles) {
      for (let i = 0; i < this._roles.length; i++) {
        if (user.roles.indexOf(this._roles[i].value) >= 0) {
          return this._roles[i].name;
        }
      }
    }
    return 'Desconocido';

  }


  saveUser(organizationId: string, user: User): Observable<User> {
    return this._httpService.doApiPutConnection<User>('/api/organization/' + organizationId + '/users/' + user.id, user)
      .pipe(
        map<User, User>(newUser => new User(newUser)),
        tap((newUser: User) => {
          for (let i = 0; i < this.usersByOrganization[organizationId].length; i++) {
            if (newUser.id === this.usersByOrganization[organizationId][i].id) {
              this.usersByOrganization[organizationId][i] = newUser;
            }
          }
        }),
      );
  }

  newUser(organizationId: string, user: User): Observable<User> {
    return this._httpService.doApiPostConnection<User>('/api/organization/' + organizationId + '/users/', user)
      .pipe(
        map<User, User>(newUser => new User(newUser)),
        tap((newUser: User) => {
          console.log('Adding new user', newUser);
          if (!this.usersByOrganization[organizationId]) {
            this.usersByOrganization[organizationId] = [];
          }
          this.usersByOrganization[organizationId].push(newUser);
          this.refreshOrganization(organizationId);
        }),
      );
  }

  removeUser(organizationId: string, user: User): Observable<User> {
    return this._httpService.doApiDeleteConnection<User>('/api/organization/' + organizationId + '/users/' + user.id)
      .pipe(
        tap((doc) => {
          let i = this.usersByOrganization[organizationId].length;
          while (i--) {
            if (this.usersByOrganization[organizationId][i].id === user.id) {
              console.log('Removed user: ' + user.id);
              this.usersByOrganization[organizationId].splice(i, 1);
            }
          }
          this.refreshOrganization(organizationId);
        }),
      );
  }

  private refreshOrganization(organizationId: string) {
    const user = this._oAuthService.getIdentityClaims() as User;
    if (!user || !user.roles || user.roles.indexOf('ROLE_ADMIN') < 0) {
      this._organizationService.getOrganizations(organizationId, true)
        .subscribe((organization) => {
          this._organizationService.setOrganization(organization[0]);
        });
    }
  }

  private onEditShop(shop: Shop) {
    // test
    if (shop && shop.organization && shop.organization.id) {
      if (this.usersByOrganization[shop.organization.id]) {
        for (let i = 0; i < this.usersByOrganization[shop.organization.id].length; i++) {
          const user: User = this.usersByOrganization[shop.organization.id][i];
          if (user.shop && user.shop.id && user.shop.id === shop.id) {
            // update user shop
            user.shop = shop;
          }
        }
      }
    }
  }
}
